import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-toastify/dist/ReactToastify.css';

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import AuthLayout from "layouts/Auth";
import AuthProvider from "contexts/AuthContext";
import WhatsappPublic from "views/WhatsappPublic";
import RelatoriosLayout from "layouts/Relatorios";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeContextWrapper>
    <AuthProvider>
      <BackgroundColorWrapper>
        <BrowserRouter>
          <Switch>
            <Route path="/login" render={(props) => <AuthLayout router="login" {...props} />} />
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Route path="/relatorios" render={(props) => <RelatoriosLayout {...props} />} />
            <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
            <Route path="/whatsapp-qr/:id" render={(props) => <WhatsappPublic {...props} />} />
            <Redirect from="/" to="/admin/message" />
          </Switch>
        </BrowserRouter>
      </BackgroundColorWrapper>
    </AuthProvider>
  </ThemeContextWrapper>
);
