import styled from "styled-components";

export const IconLoad = styled.div`
    @keyframes ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    svg {
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    }
`
export const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ProgressBox = styled.div`
    width: 100%;
`;

export const ProgressBar = styled.div`
    width: 100%;
`;

export const ProgressLine = styled.div`
    width: ${p => p.percentage}%;
    height: 10px;
    background-color: #57b36b;
`;

export const SendingText = styled.h4`
    
`;

export const SendingTop = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const SendingCount = styled.h4`
    
`;

export const SendingPercentageText= styled.h3`
    margin-top: 12px;
`;