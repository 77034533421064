import { Col, Input, Row } from "reactstrap"




export const AgeSearch = (props) => {
    const {getSearch, search, style} = props;
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start"
        }}>
            <span>De</span>
            <Input style={{width: 70, margin: "0 12px",...style}} type="text" onChange={e => getSearch(e.target.value, "minAge")}/>
            <span>Até</span>
            <Input style={{width: 70, margin: "0 12px",...style}}  type="text" onChange={e => getSearch(e.target.value, "maxAge")}/>
            <span>Anos</span>
        </div>
    )
}