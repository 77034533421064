import styled from "styled-components";


export const Paginator = styled.div`
    width: 320px;
    display: flex;
    align-items: center;
`;

export const PaginatorSelect = styled.div`
    input {
       border: 1px solid #e14eca;
    }
`;

export const PaginatorButton = styled.button`
    padding: 8px 12px;
    background-color: #e14eca;
    border: none;
    margin: 0 2px;
    border-radius: 3px;
    svg {
        color: #fff;
    }
`;