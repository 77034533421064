import { AuthContext } from "contexts/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, CardFooter, CardHeader, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Api from "services/api";
import {FiEdit} from "react-icons/fi"
import {AiFillDelete} from "react-icons/ai"
import {MdPreview} from "react-icons/md"
import ReactNotificationAlert from "react-notification-alert";
import { RiCheckboxFill, RiCheckboxBlankLine } from "react-icons/ri";
import { GrClose} from "react-icons/gr";
import vars from "../services/constants.json"
const api_url = vars.WSSERVER;
const fieldsPattern = {
    id: "",
    name: "",
    username: "",
    email: "",
    whatsapp: "",
    subdomain: "",
    color: "",
    logo: "",
    logo_file: {},
    password: "",
    rePassword: "",
    showpassword: false,
    dontus_id: "",
    dontus_password: "",
    dontus_username: "",
    dontus_order: 0,
    clinica: false,
    method: "create"
}
export default function Users(){
    const notificationAlertRef = useRef(null);
    const [list, setList] = useState([])
    const [method, setMethod] = useState("create");
    const [auth] = useContext(AuthContext);
    const [wpp_preview, set_wpp_preview] = useState({
        status: false,
        img: ""
    });
    const [fieldStatus, setFieldStatus] = useState({
        name: "",
        username: "",
        email: "",
        whatsapp: "",
        logo: "",
        logo_file: {},
        subdomain: "",
        color: "",
        password: "",
        rePassword: "",
    });
    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });


    const [fields, setFields] = useState(fieldsPattern);
    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validFields = () => {
        let err = {
            name: "",
            username: "",
            email: "",
            whatsapp: "",
            logo_file: "",
            logo: "",
            color:    "",
            password: "",
            rePassword: ""
        }
        let hasErr = false;
        
        if(!validateEmail(fields.email)){
            err = {...err, email: "Email inválido!"};
            hasErr = true;
        }
        if(fields.name===""){
            err = {...err, name: "Digite um nome!"}
            hasErr = true;
        }
        if(fields.username===""){
            err = {...err, username: "Digite um nome de usuário!"}
            hasErr = true;
        }
        if(fields.password.length < 6){
            err = {...err, password: "Digite uma senha com o mínimo de 6 caractéres"}
            hasErr = true;
        }else if(fields.password != fields.rePassword) {
            setFields({...fields, showpassword: true})
            err = {...err, password: "As senhas digitadas não coincidem.", rePassword: "As senhas digitadas não coincidem."}
            hasErr = true;
        }
        setFieldStatus(err);
        return !hasErr
    }

    const onChange = (value, name) => {
        console.log("value:", value);
        if(name=="username"){
            value = value.replace(/[^0-9a-zA-Z]/g,'')
        }
        if(name =="password" || name == "rePassword"){
            value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "")
        }
        setFields({...fields, [name]:value})
    }

    const toggle = () => setModal({...modal, status: !modal.status});

    const uploadLogo = async (logo) => {
        var file = new FormData();
        file.append("media",logo)

        const res = await Api("post", "admin/upload", file, {
            headers: {
                "x-access-token": localStorage.getItem("terces")
            }
        });
        console.log("upload logo:",res.data);
        return res?.data?.file
    }

    const createUser = async () => {
        if( !validFields() ) return
        let logo = fields.logo || "";
        if(fields.logo_file.size) {
            logo = await uploadLogo(fields.logo_file)
        }

        const data = {
            name: fields.name,
            username:  fields.username,
            email:  fields.email,
            whatsapp: fields.whatsapp,
            logo: logo,
            subdomain: fields.subdomain,
            color: fields.color,
            password: fields.password,
                  
            dontus_id: fields.dontus_id,
            dontus_username: fields.dontus_username,
            dontus_password: fields.dontus_password,
            dontus_order: fields.dontus_order,
            clinica: fields.clinica,
        }
        try {
            const res = await Api("post", "admin/create-user", data, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            notify("success", "Usuário criado com sucesso!")
            setFields(fieldsPattern)
            getUsers();
        } catch (error) {
            
        }
    }

    const editUser = async () => {

        let logo = fields.logo || "";
        if(fields.logo_file.size) {
            console.log("upload logo");
            logo = await uploadLogo(fields.logo_file)
        }

        const data = {
            id: fields.id,
            name: fields.name,
            username:  fields.username,
            email:  fields.email,
            whatsapp: fields.whatsapp,
            subdomain: fields.subdomain,
            color: fields.color,
            logo: logo,
            password: fields.password,
            
            dontus_id: fields.dontus_id,
            dontus_username: fields.dontus_username,
            dontus_password: fields.dontus_password,
            dontus_order: fields.dontus_order,
            clinica: fields.clinica,
        }
        try {
            const res = await Api("put", "admin/update-user", data, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            notify("success", "Usuário atualizado com sucesso!")
            getUsers();
        } catch (error) {
            
        }
    }

    const notify = (type, text) => {
       
        var options = {};
        options = {
          place: "tr",
          message: (
            <div>
              <div>
                {text}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const getUsers = async () => {
        try {
            const res = await Api("get", "admin/list", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data.length) {
                setList(res.data);
            }
        } catch (error) {
            console.log("error:",error);
        }
    }

    const PreviewWpp = async (userId) => {
        try {
            const res = await Api("post", "admin/preview-wpp", {userId}, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                set_wpp_preview({
                    status: true,
                    img: res.data.img
                })
            }
        } catch (error) {
            console.log("error:",error);
        }
    }

    const deleteUser = async (id) => {
        try {
            const res = await Api("post", "admin/delete-user", {
                id,
            }, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data.error){
                return notify("danger",res.data.msg)
            }
            notify("success", "Usuário deletado com sucesso!");
            getUsers();
        } catch (error) {
            console.log("error:",error);
        }
    } 

    useEffect(()=>{
        if(!list.length){
            getUsers();
        }
    },[list]);
    return (
        <div className="content">
            {
                wpp_preview.status ?
                    <div
                        style={{position: 'fixed', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 999, top: 0, left: 0, width: '100%', height: "100%"}}>
                        <div style={{backgroundColor: "#fff", width: "80%", height: "80%", padding: 24, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{position: 'relative'}}>
                                <span style={{
                                    position: 'absolute',
                                    right: -40,
                                }}>
                                    <GrClose onClick={() => set_wpp_preview({status: false})} style={{fontSize: 24, cursor: 'pointer'}}/>
                                </span>
                                <img style={{height: '100%'}} src={vars.WSSERVER + "/" + wpp_preview.img} />
                            </div>
                        </div>
                    </div>
                : null
            }
            <Modal isOpen={modal.status} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {modal.text}
                </ModalBody>
                <ModalFooter style={{padding: "24px"}}>
                    <Button color="primary" onClick={()=>{
                        modal.onOk(modal.id);
                        setModal({
                            status: false,
                            text: "",
                            buttonOk: "",
                            onOk: false
                        })
                    }}>{modal.buttonOk}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            <ReactNotificationAlert ref={notificationAlertRef} />
            <Row>
                <Col md="6">
                    <Card style={{padding: 12}}>
                        <CardHeader>
                            <h2>Lista de Usuários</h2>
                        </CardHeader>
                        <Table>
                        <thead className="text-primary">
                            <tr>
                                <th>Nome</th>
                                <th>Nome de usuário</th>
                                <th>whatsapp status</th>
                                <th>Preview Whatsapp</th>
                                <th>Editar</th>
                                <th>Apagar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map(i=>(
                                    <tr key={i._id}>
                                        <td>{i.name}</td>
                                        <td>{i.username}</td>
                                        <td>{i.whatsapp_status}</td>
                                        <td> 
                                            {
                                                i.whatsapp_status != 'OFFLINE' ?
                                                    <MdPreview onClick={()=>PreviewWpp(i._id)} style={{fontSize: 18, color: "#ce56c7", cursor: 'pointer'}}/>
                                                : null
                                            }
                                            
                                        </td>
                                        <td><span onClick={()=>setFields(
                                            {
                                                id: i._id,
                                                name: i.name,
                                                username: i.username,
                                                email: i.email,
                                                whatsapp: i.whatsapp || "",
                                                subdomain: i.subdomain || "",
                                                logo: i.logo|| "",
                                                color: i.color || "",
                                                logo_file: {},
                                                password: "",
                                                rePassword: "",

                                                dontus_id: i.dontus_id || "",
                                                dontus_username: i.dontus_username || "",
                                                dontus_password: i.dontus_password || "",
                                                dontus_order: i.dontus_order || "",
                                                clinica: i.clinica || "",

                                                method: "edit"
                                            }
                                        )} style={{cursor: "pointer"}}> {fields.id === i._id ? "Editando" : ""} <FiEdit style={{fontSize: 18, color: "#ce56c7"}}/></span></td>
                                        <td><span style={{cursor: "pointer"}} onClick={()=>{
                                            setModal({
                                                status: true,
                                                text: "Deseja Deletar este Usuário?",
                                                buttonOk: "Deletar",
                                                id: i._id,
                                                onOk: deleteUser
                                            })
                                        }}><AiFillDelete style={{fontSize: 18, color: "#ce56c7"}}/></span></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        </Table>
                    </Card>
                </Col>
                <Col md="6">
                    <Card  style={{padding: 12}}>
                        <CardHeader>
                            <h2>{fields.method === "create" ? "Criar Usuário" : `Editando Usuário: ${fields.name}`}</h2>
                        </CardHeader>
                        <Row>
                            <Col md="5">
                                <FormGroup>
                                    <label>Subdomínio:</label>
                                    <Input
                                        value={fields.subdomain}
                                        onChange={(e) => onChange(e.target.value, "subdomain")}
                                        placeholder="nomeclinica"
                                        type="text"
                                        />
                                        <FormFeedback >
                                            {fieldStatus.subdomain}
                                        </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <label>Cor</label>
                                    <Input
                                        value={fields.color}
                                        onChange={(e) => onChange(e.target.value, "color")}
                                        placeholder="color"
                                        type="color"
                                        />
                                        <FormFeedback >
                                            {fieldStatus.color}
                                        </FormFeedback>
                                </FormGroup>
                            </Col>
                     
                            
                        </Row>
                        <Row>
                            <Col md="5">
                                <label>Logo</label>
                                <input
                                    onChange={(e) => onChange(e.target.files[0], "logo_file")}
                                    placeholder="Escolher imagem"
                                    type="file"
                                    />
                            </Col>
                            <Col md="5">
                                <FormGroup>
                                    {
                                        fields.logo_file.size || fields.logo ? <div className="d-flex justify-content-center" style={{backgroundColor: "#fff", borderRadius: 8, padding: 24}}>  <img style={{height: 100}} src={fields.logo_file.size ? URL.createObjectURL(fields.logo_file) : api_url + "/" + fields.logo }/> </div> : null
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="8">
                                <FormGroup>
                                    <label>Nome: <span style={{color: "red"}}>*</span></label>
                                    <Input
                                        {...{invalid: fieldStatus.name != "" ? true : false}}
                                        value={fields.name}
                                        onChange={(e) => onChange(e.target.value, "name")}
                                        placeholder="Nome do Usuário"
                                        type="text"
                                        />
                                        <FormFeedback >
                                            {fieldStatus.name}
                                        </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>Nome de usuário:  <span style={{color: "red"}}>*</span></label>
                                    <Input
                                         {...{invalid: fieldStatus.username != "" ? true : false}}
                                        value={fields.username}
                                        onChange={(e) => onChange(e.target.value, "username")}
                                        placeholder="Username"
                                        type="text"
                                        />
                                        <FormFeedback >
                                            {fieldStatus.username}
                                        </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md="7">
                                <FormGroup>
                                    <label>Email: <span style={{color: "red"}}>*</span></label>
                                    <Input
                                        {...{invalid: fieldStatus.email != "" ? true : false}}
                                        value={fields.email}
                                        onChange={(e) => onChange(e.target.value, "email")}
                                        placeholder="Email"
                                        type="text"
                                        />
                                        <FormFeedback >
                                            {fieldStatus.email}
                                        </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md="5">
                                <FormGroup>
                                    <label>Whatsapp: (opcional)</label>
                                    <Input
                                        {...{invalid: fieldStatus.whatsapp != "" ? true : false}}
                                        value={fields.whatsapp}
                                        onChange={(e) => onChange(e.target.value, "whatsapp")}
                                        placeholder="Número de whatsapp"
                                        type="number"
                                        />
                                        <FormFeedback >
                                            {fieldStatus.whatsapp}
                                        </FormFeedback>
                                </FormGroup>
                            </Col>
                   


                            
                            <Col md="12" style={{marginTop: 32, marginBottom: 32}}>
                                <FormGroup check>
                                    <label check>
                                        {
                                            fields.clinica ? <RiCheckboxFill style={{fontSize: 24, color: "#e14eca"}}/> : <RiCheckboxBlankLine  style={{fontSize: 24, color: "#e14eca"}}/>
                                        }
                                        <span style={{marginLeft: 4}}>Clinica Ativa:</span>
                                        <input
                                            onChange={(e) => onChange(e.target.checked, "clinica")}
                                            type="checkbox"
                                            />
                                    </label>
                                </FormGroup>
                                <Row>
                                    <Col md="2">
                                        <FormGroup>
                                            <label>ID Dontus:</label>
                                            <Input
                                                value={fields.dontus_id}
                                                onChange={(e) => onChange(e.target.value, "dontus_id")}
                                                type={fields.dontus_id ? "text" : "dontus_id"}
                                                />
                                                <FormFeedback >
                                                    {fieldStatus.dontus_id}
                                                </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Usuário Dontus:  </label>
                                            <Input
                                                value={fields.dontus_username}
                                                onChange={(e) => onChange(e.target.value, "dontus_username")}
                                                type={fields.dontus_username ? "text" : "dontus_username"}
                                                />
                                                <FormFeedback >
                                                    {fieldStatus.dontus_username}
                                                </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <label>Senha Dontus:  </label>
                                            <Input
                                                value={fields.dontus_password}
                                                onChange={(e) => onChange(e.target.value, "dontus_password")}
                                                type={fields.showpassword ? "text" : "dontus_password"}
                                                />
                                                <FormFeedback >
                                                    {fieldStatus.dontus_password}
                                                </FormFeedback>
                                        </FormGroup>
                                    </Col>
                          
                                    <Col md="2">
                                        <FormGroup>
                                            <label>Ordem:</label>
                                            <Input
                                                p={0}
                                                value={fields.dontus_order}
                                                onChange={(e) => onChange(e.target.value, "dontus_order")}
                                                type={"dontus_order"}
                                                />
                                                <FormFeedback >
                                                    {fieldStatus.dontus_order}
                                                </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>






                            <Col md="6">
                                <FormGroup>
                                    <label>Senha:  <span style={{color: "red"}}>*</span></label>
                                    <Input
                                        {...{invalid: fieldStatus.password != "" ? true : false}}
                                        value={fields.password}
                                        onChange={(e) => onChange(e.target.value, "password")}
                                        placeholder="Senha:"
                                        type={fields.showpassword ? "text" : "password"}
                                        />
                                        <FormFeedback >
                                            {fieldStatus.password}
                                        </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Repetir Senha:  <span style={{color: "red"}}>*</span></label>
                                    <Input
                                         {...{invalid: fieldStatus.rePassword != "" ? true : false}}
                                        value={fields.rePassword}
                                        onChange={(e) => onChange(e.target.value, "rePassword")}
                                        placeholder="Repita a senha:"
                                        type={fields.showpassword ? "text" : "password"}
                                        />
                                        <FormFeedback >
                                            {fieldStatus.rePassword}
                                        </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup check>
                                    <label check>
                                        {
                                            fields.showpassword ? <RiCheckboxFill style={{fontSize: 24, color: "#e14eca"}}/> : <RiCheckboxBlankLine  style={{fontSize: 24, color: "#e14eca"}}/>
                                        }
                                        <span style={{marginLeft: 4}}>Mostrar senha:</span>
                                        <input
                                            onChange={(e) => onChange(e.target.checked, "showpassword")}
                                            type="checkbox"
                                            />
                                    </label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <CardFooter>
                            {
                                fields.method != "create" ?
                                <span>
                                    <Button onClick={()=>{
                                        setFields(fieldsPattern)
                                    }} style={{marginTop: 32, marginRight: 12}} color="primary">Criar Novo Usuário</Button>
                                </span>
                                : null
                            }
                            <span><Button onClick={()=>{
                                if(fields.method==="create"){
                                    setModal({
                                        status: true,
                                        text: "Deseja Criar este Usuário?",
                                        buttonOk: "Criar",
                                        onOk: createUser
                                    });
                                } else {
                                    setModal({
                                        status: true,
                                        text: "Deseja Atualizar este Usuário?",
                                        buttonOk: "Atualizar",
                                        onOk: editUser
                                    });
                                }
                            }} style={{marginTop: 32}} color="primary">{fields.method === "create" ? "Criar Usuário" : "Atualizar"}</Button></span>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}