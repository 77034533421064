import { BsEmojiSmile, BsTypeBold, BsTypeItalic, BsTypeStrikethrough } from "react-icons/bs";
import { EditorTextBlock, EditorTextarea, EmojiPickerBlock, EmojiPickerBox, IconBlock, TextEditor, TextEditorPanel } from "./style";
import { VscFileSubmodule } from "react-icons/vsc";
import { useState } from "react";
import PickerEmoji from 'emoji-picker-react';
import { RiSubscript2 } from "react-icons/ri";




export default function FacebookTextEditor({fields, setFields}){
    const [isPicking, setIsPicking] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);
    const [selectedVar, setSelectVar] = useState("none");
    const [selectedModel, setSelectModel] = useState("none");
 

    const getSel = (init, end) =>  {
        var txtarea = document.getElementById("facebook-editor");
        var start = txtarea.selectionStart;
        var finish = txtarea.selectionEnd;
        var allText = txtarea.value;
        var sel = allText.substring(start, finish);
        if(sel=="") return
        var newText = allText.substring(0, start) + `${init}` + sel + `${end}` + allText.substring(finish, allText.length);
        setFields({...fields, text: newText})
    }

    const setBold = () => {
        getSel('<b>', '</b>')
    }

    const setSubscript = () => {
        getSel('<sub>', '</sub>')
    }

    const setItalic = () => {
        getSel('<i>', '</i>')
    }

    const setStrikethrough = () => {
        getSel('~')
    }
    const onEmojiClick = (event, emojiObject) => {
        setFields({...fields, text: fields.text + emojiObject.emoji})
        setIsPicking(!isPicking)
    };
    return (
        <TextEditor>
            <TextEditorPanel>
                <IconBlock onClick={()=>setBold()}><BsTypeBold/></IconBlock>
                <IconBlock onClick={()=>setItalic()}><BsTypeItalic/></IconBlock>
                <IconBlock onClick={()=>setSubscript()}><RiSubscript2/></IconBlock>
                <IconBlock isPicking={isPicking} onClick={()=>setIsPicking(!isPicking)}><BsEmojiSmile/></IconBlock>
                {/* <IconBlock isPicking={isSelecting} onClick={()=>setIsSelecting(!isSelecting)}><VscFileSubmodule/></IconBlock> */}
            </TextEditorPanel>
            <EditorTextBlock>
                {
                    isPicking &&
                    <EmojiPickerBlock>
                        <EmojiPickerBox>
                            <PickerEmoji onEmojiClick={onEmojiClick} />
                        </EmojiPickerBox>
                    </EmojiPickerBlock>
                }
                <EditorTextarea rows={12} id="facebook-editor" value={fields.text} onChange={e => setFields({...fields, text: e.target.value})}/>
            </EditorTextBlock>
        </TextEditor>
        
    )
}
