import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, CustomInput, FormGroup, Input, Label, Row, Table, Modal, ModalBody, ModalFooter, ModalHeader, } from "reactstrap";
import styled from "styled-components";
import WhatappTextEditor from "../components/WhatsappTextEditor";
import ReactNotificationAlert from "react-notification-alert";
import MsgTimeLine from "components/MsgTimeLime";
import { v4 as uuidv4 } from 'uuid';
import Api from "services/api";

let _timer = 0;
const newMsg = () => {
    return {
        id: uuidv4(),
        method: "new",
        media: {
            has: false,
            type: "",
            filename: "",
            origin: "local"
        },
        texto: "",
    }
}

function MsgModel(){
    const notificationAlertRef = useRef(null);
    const [fields, setFields] = useState({
        msgTitle: "",
        method: 'new',
        mensagens: [],
        columns: [],
        msgModel: false
    })

    const [sending, setSending] = useState({
        status: false,
        type: "",
        name: "",
        sended: false,
        percentage: 0
    });
    const [drag, setDrag] = useState(false);
    const [currentMsg, setCurrentMsg] = useState(newMsg);
    const [msgModels, setMsgModels] = useState(false);

    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });

    const geType = (type) => {
        if(type == "video") {
            return "o Vídeo"
        }
        if(type == "image") {
            return "a Imagem"
        }
        return "o Arquivo"
    }

    
    function percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }
    const makeMsgModels = (model) => {
        const models = []; 
        for (let i = 0; i < model.length; i++) {
            const msgs = model[i].mensagens;
            console.log(`msg ${i}:`,msgs[i]);
            let _msgs = [];
            for (let x = 0; x < msgs.length; x++) {
                const media = {
                    has: msgs[x].media.has ? true : false,
                    filename: msgs[x].media.has ? msgs[x].media.filename : "",
                    type: msgs[x].media.has ? msgs[x].media.type : "",
                    origin: "server"
                }
                _msgs.push({
                    id: uuidv4(),
                    media,
                    texto: msgs[x].texto
                })
            }
            models.push({
                id: model[i]._id,
                name: model[i].name,
                mensagens: _msgs
            })
        }
        console.log("models:",models);
        setMsgModels(models)
    }
    const createMsg = async () => {
        let msgs = fields.mensagens;
        let _msgs = [];
        for (let x = 0; x < msgs.length; x++) {
            let hasMedia = false;
            let i = msgs[x];
            let obj = {...i}
            if(i.media.has) {
                setSending({status: true, name: i.media.file.name, type: geType(i.type), progress: 0})
                hasMedia = true;
                var file = new FormData();
                const fileSize = i.media.file.size;
                file.append("media",i.media.file)
                try {
                    const res = await Api("post", "send/upload", file, {
                        headers: {
                            "x-access-token": localStorage.getItem("terces")
                        },
                        onUploadProgress: progressEvent => {
                            setSending({status: true, sended: false, name: i.media.file.name, type: geType(i.type), percentage: percentage(progressEvent.loaded, progressEvent.loaded >= fileSize ? progressEvent.loaded : fileSize)})
                        }
                    });
                    if(res.data && res.data.file && res.data.file.size) {
                        obj.media.filename = res.data.file.filename
                    }
                } catch (error) {
                    console.log("error:",error);
                }
            }
            _msgs.push({
                media: {
                    filename: obj.media.has ? obj.media.filename : "",
                    has: obj.media.has,
                    type: obj.media.type
                },
                texto: obj.texto
            })
        }

        let mensagens = _msgs.map( i => {
            return {
                media: {
                    filename:  i.media.has ? i.media.filename : "",
                    has: i.media.has,
                    type: i.media.type
                },
                texto: i.texto
            }
        })

        const data = {
            name: fields.msgTitle,
            mensagens,
            method: fields.method,
            id: fields.id,
            date: (new Date()).toLocaleString()
        }

        try {
            const res = await Api("post", "message/create", data, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data){
                notify("Modelo Salvo com Sucesso!","success");
            }
            setFields({
                mensagens: [],
                columns: [],
                id: '',
                method: 'new',
                msgTitle: ""
            })
            setCurrentMsg(newMsg())
        } catch (error) {
            console.log("error:",error);
        }
    }

    const getMsgModels = async () => {
        try {
            const res = await Api("get", "message/list", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            console.log("getMsgModels:",res.data);
            makeMsgModels(res.data)
        } catch (error) {
            console.log("error:",error);
        }
    }

    const addMsg = async () => {
        if(!currentMsg.media.has && currentMsg.texto == "") return


        if(currentMsg.method === 'edit') {
            setFields({...fields, method: 'edit',mensagens: fields.mensagens.map( i => {
                if(i.id === currentMsg.id) {
                    return currentMsg
                } else {
                    return i
                }
            })})
            setCurrentMsg(newMsg());
        } if(fields.method === "edit") {
            setFields({...fields, method: 'edit', mensagens: [ {
                ...currentMsg
            }, ...fields.mensagens]});
            setCurrentMsg(newMsg());
        } else {
            setFields({...fields, method: 'new', mensagens: [ {
                ...currentMsg
            }, ...fields.mensagens]});
            setCurrentMsg(newMsg());
        }

    }
  
    const notify = (text, type) => {
        var options = {};
        options = {
          place: "tr",
          message: (
            <div>
              <div>
                {text}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };


    const toggle = () => setModal({...modal, status: !modal.status});

    return (
        <div className="content">
            <ReactNotificationAlert ref={notificationAlertRef} />
            <Modal isOpen={modal.status} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {modal.text}
                </ModalBody>
                <ModalFooter style={{padding: "24px"}}>
                    <Button color="primary" onClick={()=>{
                        modal.onOk();
                        setModal({
                            status: false,
                            text: "",
                            buttonOk: "",
                            onOk: false
                        })
                    }}>{modal.buttonOk}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            <Row>
                <Col md="8">
                    <Card>
                        <CardHeader>
                            <h3 className="title">Criar Modelos de Mensagens</h3>
                        </CardHeader>
                        <CardBody>
                            <Button onClick={()=>setFields({...fields, contactsReady: false})} className="btn-fill" color="secondary" type="submit">
                                Voltar para Configurações do Arquivo
                            </Button>
                            <Row>
                                <Col>
                                    <WhatappTextEditor getMsgModels={getMsgModels} msgModels={msgModels} setMsgModels={setMsgModels} currentMsg={currentMsg} setCurrentMsg={setCurrentMsg} newMsg={newMsg} fields={fields} setFields={setFields}/>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <FormGroup>
                                <Label>Título da mensagem:</Label>
                                <Input value={fields.msgTitle} type="text" onChange={(e) => {
                                    setFields({...fields, msgTitle: e.target.value})
                                }}/>
                            </FormGroup>
                            <Button onClick={()=>addMsg()} className="btn-fill" color="secondary" type="submit" disabled={!( currentMsg.media.has || currentMsg.texto != "" ? true : false)}>
                                { currentMsg.method === "new" ? "Adicionar Mensagem" : "Atualizar" }
                            </Button>
                            <Button onClick={ () => {
                                setModal({
                                    status: true,
                                    text: "Deseja Salvar Este Modelo de Mensagem?",
                                    buttonOk: "Salvar",
                                    onOk: createMsg
                                })
                            } } className="btn-fill" color="success" type="submit" disabled={( fields.msgTitle == "" || fields.mensagens.length === 0 ? true : false)}>
                                Salvar Modelo
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="4">
                    <Card>
                        <CardHeader>
                            <h3 className="title">Visualizar</h3>
                        </CardHeader>
                        <CardBody>
                            <MsgTimeLine fields={fields} setFields={setFields} currentMsg={currentMsg} setCurrentMsg={setCurrentMsg} vh={70}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}


export default memo(MsgModel);