import styled from "styled-components";

export const EditorTextBlock = styled.section`
    position: relative;
`;

export const EditorText = styled.textarea`
    width: 100%;
    background-color: rgba(0,0,0, .0);
    border: none;
    padding: 16px;
    color: #fff;
    font-size: 18px;
    :focus {
        border: none;
        outline: none;
    }
`;

export const EmojiPickerBlock = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0, .3);
`;

export const EmojiPickerBox = styled.div`
`;

export const EditorBlock = styled.div`
    width: 100%;
    border: 1px solid #2b3553;
`;

export const EditorPanel = styled.section`
    width: 100%;
    display: flex;
    justify-content: left;
    border-bottom: 1px solid #2b3553;
`;

export const IconBlock = styled.div`
    width: 64px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${p => p.isPicking ? "#d058c7" :"none"};
    color: ${p => p.isPicking ? "#fff" :"none"};
    cursor: pointer;
    :hover {
        background-color: #2b3553;
    }
    svg {
        font-size: 20px;
    }
`;

export const IconBlockPickMedia = styled.div`
    display: inline-block;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    .current-media {
        padding: 4px 12px;
        background-color: #d058c7;
        border-radius: 4px;
        color: #fff;
    }
    span svg {
        cursor: pointer;
        margin-left: 4px;
        color: red;
    } 
`;

export const FileSelector = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0, .3);
    padding: 24px;
    section {
        width: 100%;
    }
`;
export const SelectVarBlock = styled.div`
    display: flex;
    align-items: center;
    button {
        width: 250px;
    }
`;
export const SelectVarButton = styled.button`
    background-color: #d058c7;
    border: none;
    color: #fff;
    padding: 4px 12px;
    border: 3px;
`;
