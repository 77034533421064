import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row, Table } from "reactstrap";
import Api from "services/api";
import { Progress, ProgressBar, SucesssTitle, WarningTitle } from "./style";

export default function SaveContact({contactFields, setContactFields}){
    
    const [uploadProgress, setUploadProgress] = useState(0);
    const [contacts, setContacts] = useState(false);
    const [uploadFinish, setUploadFinish] = useState();
    function percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }

    const makeContacts = async () => {
        let rows =  contactFields.rows;
        let _i =  Object.keys(contactFields.indexies);
        let usedKeys = {};
        for (let index = 0; index < _i.length; index++) {
            const i = _i[index];
            if(typeof contactFields.indexies[i].index != "boolean"){
                usedKeys[contactFields.indexies[i].key] = contactFields.indexies[i].index
            }
        }
        let _x = Object.keys(usedKeys);
        let _contacts = []
        for (let index = 0; index < rows.length; index++) {
            let _c = {}
            for (let _index = 0; _index < _x.length; _index++) {
                const i = usedKeys[_x[_index]];
                _c[_x[_index]] = rows[index][i];
            }
            let _v = 0;
            let _y = Object.keys(_c);
            for (let index = 0; index < _y.length; index++) {
                if(_c[_y[index]]==""){
                    _v++;
                }
            }
            if(_v != _x.length){
                _contacts.push(_c)
            }
        }
        setContacts(_contacts)
    }

    const sendContacts = async () => {
   
        try {
            const fileSize = JSON.stringify(contacts).length;
            const res = await Api("post", "contact/send", {contacts, filename: contactFields.contactfile.name}, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                },
                onUploadProgress: progressEvent => {
                    setUploadProgress(percentage(progressEvent.loaded, progressEvent.loaded >= fileSize ? progressEvent.loaded : fileSize))
                }
            });

            if(res.data) {
                setUploadFinish(res.data)
            }

        } catch (error) {
            console.log("error:",error);
        }
    }

    useEffect(()=>{
        if(!contacts){
            makeContacts();
        }
    },[contacts]);

    return (
        <div className="content">
            <Row>
                <Col md="5">
                    <Card>
                        <CardHeader>
                            <Button onClick={ () => setContactFields({...contactFields, step: 2}) } color="secondary">Voltar</Button>
                        </CardHeader>
                        <CardBody>
                            <div style={{height: "70vh"}}>
                                <Table>
                                    <thead className="text-primary">
                                        <tr>
                                            {
                                                contactFields.cols.map((i, k)=>(
                                                    <th key={`xls-col-${k}`}>{i}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            contactFields.rows.map((i, k)=>(
                                                <tr>
                                                    {
                                                        i.map(x => (
                                                            <td>{x}</td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="5">
                    <Card>
                        <CardHeader>
                            <h3>Contatos: {contacts.length}</h3>
                        </CardHeader>
                        <ProgressBar>
                            <Progress progress={uploadProgress} duplicated={uploadFinish && uploadFinish.duplicated ? true: false}/>
                        </ProgressBar>
                        <CardBody>
                            <WarningTitle>
                                {
                                    uploadFinish && uploadFinish.duplicated ?
                                        <p>Este documento já foi enviado, para reenviar basta deletá-lo na <a onClick={()=>{
                                            setContactFields({...contactFields, sendlist: true})
                                        }}>Lista de Contatos Enviadas</a></p>
                                    : null
                                }
                            </WarningTitle>
                            <SucesssTitle>
                                {
                                    uploadFinish && !uploadFinish.duplicated ?
                                        <p>Documento salvo com sucesso</p>
                                    : null
                                }
                            </SucesssTitle>
                            <Button onClick={ () => sendContacts() } disabled={!contacts || (uploadFinish && uploadFinish.duplicated ? true: false)} color="primary">Salvar no Servidor</Button>
                                                        
                            {
                                !uploadFinish ?
                                    <Button onClick={ () => {
                                        setContactFields({
                                            ...contactFields,
                                            step: 1,
                                            contactfile: false})
                                    } } color="danger">Cancelar</Button>
                                : null
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}