import { memo, useEffect, useState } from "react";
import { Paginator, PaginatorButton, PaginatorSelect } from "./style";
import { v4 as uuid } from "uuid";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Input } from "reactstrap";

function Paginate({contacts, getContacts}){
    const [pagin, setPagin] = useState({
        created: false,
        list: []
    });

    const beforePage = (n) => {
        if(contacts.page === 1) {
            return
        } 
        const bp = contacts.page - n;
        getContacts(bp > 0 ? bp : 1)
    }
    
    const nextPage = (n) => {
        console.log("next");
        const pagesCount = Math.ceil(parseInt(contacts.count) / parseInt(contacts.limit));
        if(contacts.page === pagesCount) {
            return
        } 
        const np = contacts.page + n;
        console.log("nextPage:", np);
        getContacts(np <= pagesCount ? np : pagesCount)
    }
    
  
    return (
        <>
            {
                contacts.count > 0 &&
                <Paginator>
                    <PaginatorButton onClick={ () => beforePage(1) }>
                        <FaAngleLeft/>
                    </PaginatorButton>
                    <PaginatorButton onClick={ () => beforePage(5)}>
                        <FaAngleDoubleLeft/>
                    </PaginatorButton>

                    <PaginatorSelect>
                        <Input type="number" min="1" max={Math.ceil(parseInt(contacts.count) / parseInt(contacts.limit))} value={contacts.page} onChange={e => getContacts( e.target.value)}></Input>
                    </PaginatorSelect>

                    <PaginatorButton onClick={ () => nextPage(5) }>
                        <FaAngleDoubleRight/>
                    </PaginatorButton>
                    <PaginatorButton onClick={ () => nextPage(1) }>
                        <FaAngleRight/>
                    </PaginatorButton>
                </Paginator>
            }
        </>
    )
}

export default memo(Paginate)