import SaveContact from "components/SaveContact";
import { useRef, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import ReactNotificationAlert from "react-notification-alert";
import { Button, Col, FormGroup, Input, Label, Row, Table,  Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Api from "services/api";
import { ColumnCard, ColumnCards } from "./style";

export default function TableColumnSelectClient({contactFields, resetContactFields, setContactFields, setColumns, setIndexies, setStep}){
    const notificationAlertRef = useRef(null);

    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });

    const [saveStatus, setSaveStatus] = useState(false);

    const setIndex = (key, crrt_key, _index) => {
        let indexies = {...contactFields.indexies};
        let _i =  Object.keys(contactFields.indexies);

        if(crrt_key!='none') {
            console.log("crrt_key:",crrt_key);
            console.log("crrt_key:",indexies);
            indexies[crrt_key].index = false;
        }

        for (let index = 0; index < _i.length; index++) {
            const i = _i[index];
            if(key==i){
                indexies[i].index = _index;
            } else if(key=="none" && crrt_key == i){
                indexies[i].index = false;
            }
        }

        let colunas = ([...contactFields.columns]).map( i => {
            if(_index==i.index){
                return {
                    ...i,
                    key: key
                }
            }
            return i
        });

        setIndexies(indexies, colunas)
    }

    const unsetIndex = (key, _index) => {
        let indexies = {...contactFields.indexies};
        let _i =  Object.keys(contactFields.indexies);
        for (let index = 0; index < _i.length; index++) {
            const i = _i[index];
            if(key==i){
                indexies[i].index = false;
            }
        }

        let colunas = ([...contactFields.columns]).map( i => {
            if(_index==i.index){
                return {
                    ...i,
                    key: 'none'
                }
            }
            return i
        });
        setIndexies(indexies, colunas)
    }

    const setSelect = (value, key, index) => {
        let colunas = [];
        if(value){
            colunas = ([...contactFields.columns]).map(i=>{
                if(index==i.index){
                    return {
                        ...i,
                        use: value
                    }
                }
                return i
            });
        } else {
            colunas = ([...contactFields.columns]).map(i=>{
                if(index==i.index){
                    unsetIndex(i.key, index)
                    return {
                        ...i,
                        key: 'none',
                        use: false
                    }
                }
                return i
            });
        }
        setColumns(colunas)
    }
    
    const saveContacts = async () => {
        try {
            const res = await Api("post", `contact/savelist`, {serverfile: contactFields.serverfile, filename: contactFields.filename, columns: contactFields.columns}, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                notify("Lista de contatos salva com sucesso", "success")
                setSaveStatus(true)
            }
        } catch (error) {
            
        }
    }



    const toggle = () => setModal({...modal, status: !modal.status});

    const notify = (text, type) => {
        var options = {};
        options = {
          place: "tr",
          message: (
            <div>
              <div>
                {text}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    return (
        <div>
            <ReactNotificationAlert ref={notificationAlertRef} />
            <Modal isOpen={modal.status} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {modal.text}
                </ModalBody>
                <ModalFooter style={{padding: "24px"}}>
                    <Button color="primary" onClick={()=>{
                        modal.onOk();
                        setModal({
                            status: false,
                            text: "",
                            buttonOk: "",
                            onOk: false
                        })
                    }}>{modal.buttonOk}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            <Row className="mb-5">
                <Col md="12">
                    <Button className="btn-fill" onClick={() => {
                        if(saveStatus) {
                            resetContactFields()
                            return
                        }
                        setContactFields({...contactFields, step: 2})
                    }}>Voltar</Button>
                    <Button disabled={saveStatus} onClick={()=> {
                          setModal({
                            status: true,
                            text: "Deseja salvar esta lista de contatos?",
                            buttonOk: "Salvar",
                            onOk: saveContacts
                        })
                    }} className="btn-fill" color="primary">Salvar</Button>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <ColumnCards id="column-cards">
                        {
                            Object.keys(contactFields.indexies).map( i => (
                                typeof contactFields.indexies[i].index != "boolean" ?
                                    <ColumnCard key={contactFields.indexies[i].key+"_card"} selected={true}>{contactFields.indexies[i].name} <BsCheckLg style={{margin: "0 10px"}}/></ColumnCard>
                                :   <ColumnCard key={contactFields.indexies[i].key+"_card"} selected={false}>{contactFields.indexies[i].name} </ColumnCard>
                            ))
                        }
                    </ColumnCards>
                </Col>
            </Row>
            <Table style={{overflow: "none"}} className="tablesorter">
                <thead className="text-primary">
                    <tr>
                        <th>Usar Coluna</th>
                        <th>Nome</th>
                        <th>Coluna</th>
                    </tr>
                </thead>
                <tbody>

                    {
                    contactFields.columns.map((i)=>(
                            <tr key={`select-col-${i.index}`}>
                                <td>
                                    <FormGroup check>
                                        <Label check>
                                            <input
                                                defaultValue=""
                                                type="checkbox"
                                                onChange={(e) => setSelect(e.target.checked, i.key ,i.index)}
                                                />
                                                <span className="form-check-sign"></span>
                                        </Label>
                                    </FormGroup>
                                </td>
                                <td>{i.name}</td>
                                <td>
                                    {
                                        i.use &&
                                        <Input type="select" value={i.key} onChange={(e) => setIndex(e.target.value, i.key ,i.index)}>
                                            <option value={"none"}>Selecione o nome da coluna</option>
                                            {
                                                Object.keys(contactFields.indexies).map(x => (
                                                    <option style={{
                                                        ...(()=>{
                                                            return typeof contactFields.indexies[x].index != "boolean" ? {
                                                                color: "#f9b2ee"
                                                            } : {}
                                                        })()
                                                    }} disabled={typeof contactFields.indexies[x].index != "boolean"} key={contactFields.indexies[x].key+"slt"} value={contactFields.indexies[x].key}>{contactFields.indexies[x].name}</option>
                                                ))
                                            }
                                        </Input>
                                    }
                                </td>
                            </tr>  
                        ))
                    }
                </tbody>
            </Table>            
        </div>
    );
}