import { QRBlock, QRCodeImageBox, QrDesc } from "./style";
import QRCoder from "react-qr-code";

export default function QRCodePublic({src, status}){

    return (
        <QRBlock>
            <QrDesc>
                {
                    status === "notLogged" ?
                        <>
                            <h4>1. Abra o WhatsApp no seu celular.</h4>
                            <h4>2. Toque em <strong>Mais opções</strong> ou <strong>Configurações</strong> e selecione<strong> Aparelhos Conectados.</strong></h4>
                            <h4>3. Aponte seu celular para esta tela para capturar o código.</h4>
                        </>
                    : 
                        <>
                            <h2>Código Expirado</h2>
                        </>
                }

                </QrDesc>
            <QRCodeImageBox>
                {
                    status === "notLogged" ?
                    <QRCoder value={src}/> : null
                    
                }
            </QRCodeImageBox>
        </QRBlock>
    )
}