/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import _routes from "routes.js";

import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { AuthContext } from "contexts/AuthContext";
import {ThemeContext} from "contexts/ThemeContext";
import Api from "services/api";
import Users from "views/Users";
import Config from "views/Config";
import ConfigAdm from "views/ConfigAdm";
import Clientes from "views/Clientes";
import ClientesAdm from "views/ClientesAdm";
import FacebookPost from "views/Facebook";
import { get_relatory_url } from "utils/get_relatory_url";

var ps;
let _admin = false;
function Admin(props) {
  const [auth, setAuth] = React.useContext(AuthContext);
  const {theme, changeTheme} = useContext(ThemeContext);
  const [routes, setRoutes] = React.useState([]);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const location = useLocation();
  const mainPanelRef = React.useRef(null);


  useEffect(()=>{
    console.log("location.pathname:",location.pathname);
    if(location.pathname != "/admin/message") {
      if(theme!="") {
        changeTheme("")
      }
    }  
  },[location.pathname])

  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  const getConfig = async () => {
    try {
          const res = await Api("get", "config/get", {
              headers: {
                  "x-access-token": localStorage.getItem("terces")
              }
          });
          setAuth({...auth,
              config: res.data
          })
      } catch (error) {
          console.log("error:",error);
      }    
  }


    const validJwt = async () => {
        const jwt = localStorage.getItem("terces")
        try {
            const res = await Api("get", "auth/valid", {
                headers: {
                    "x-access-token": jwt
                }
            });
            if(res.data.id){
                res.data.roles.map(i=>{
                    if(i=="ROLE_ADMIN"){
                        console.log("iii:",i);
                        _admin = true;
                        if(window.location.hostname.includes(get_relatory_url())) {
                          if(_admin) {
                              window.location.href = window.location.origin + "/relatorios"
                          
                          }
                        }
                    }
                })
               
                setAuth({
                    ...auth,
                    admin: _admin,
                    auth: res.data
                })
                let __routes = _routes;
                
                if(_admin){
                    __routes.push(
                        {
                          path: "/clients",
                          name: "Clientes",
                          rtlName: "Clientes",
                          icon: "tim-icons icon-single-02",
                          component: ClientesAdm,
                          layout: "/admin"
                        },
                        {
                            path: "/users",
                            name: "Usuários",
                            rtlName: "Usuários",
                            icon: "tim-icons icon-single-02",
                            component: Users,
                            layout: "/admin"
                        },
                        {
                            path: "/config",
                            name: "Configurações",
                            rtlName: "Configurações",
                            icon: "tim-icons icon-single-02",
                            component: ConfigAdm,
                            layout: "/admin"
                        }
                    )
                } else {
                  __routes.push(
                    {
                      path: "/clients",
                      name: "Clientes",
                      rtlName: "Clientes",
                      icon: "tim-icons icon-single-02",
                      component: Clientes,
                      layout: "/admin"
                    },
                    {
                      path: "/facebook",
                      name: "Facebook",
                      rtlName: "Facebook",
                      icon: "tim-icons icon-single-02",
                      component: FacebookPost,
                      layout: "/admin"
                    },
                    {
                      path: "/config",
                      name: "Configurações",
                      rtlName: "Configurações",
                      icon: "tim-icons icon-single-02",
                      component: Config,
                      layout: "/admin"
                    }
                )
                }
                setRoutes(_routes);
            } else {
                localStorage.removeItem("terces");
                window.location = "/login"
            }
        } catch (error) {
            localStorage.removeItem("terces");
            window.location = "/login"
        }
    }


  React.useEffect(()=>{
    if(auth.auth && !Object.keys(auth.auth).length){
      validJwt();
    } else
    if(auth.config && !Object.keys(auth.config).length){
      getConfig();
    }
  },[auth]);

  console.log('====================================');
  console.log("_admin:",_admin);
  console.log('====================================');
  if(window.location.hostname.includes(get_relatory_url())) {
    console.log("t1:", window.location.hostname.includes(get_relatory_url()));
    if(_admin) {
      console.log("t1:", _admin);
        window.location.href = window.location.origin + "/relatorios"
    }
  }   
  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          <div className="wrapper">
            <Sidebar
              routes={routes}
              
              toggleSidebar={toggleSidebar}
            />
            <div className="main-panel" ref={mainPanelRef} data={color}>
              <AdminNavbar
                brandText={getBrandText(location.pathname)}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
              />
              <Switch>
                {getRoutes(routes)}
              </Switch>
              {
                // we don't want the Footer to be rendered on map page
                location.pathname === "/admin/maps" ? null : <Footer fluid />
              }
            </div>
          </div>
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
