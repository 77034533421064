import { EditorBlock, EditorPanel, EditorText, EditorTextBlock, EmojiPickerBlock, EmojiPickerBox, FileSelector, IconBlock, IconBlockPickMedia, SelectVarBlock, SelectVarButton } from "./style";
import { BsEmojiSmile, BsTypeBold, BsTypeItalic, BsTypeStrikethrough } from "react-icons/bs";
import { VscFileSubmodule } from "react-icons/vsc"
import PickerEmoji from 'emoji-picker-react';
import { memo, useEffect, useRef, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, } from "reactstrap";
import {  AiOutlineCloseCircle } from "react-icons/ai";
import Api from "services/api";
import { v4 as uuidv4 } from 'uuid';
import ReactNotificationAlert from "react-notification-alert";

const WhatappTextEditor = ({fields, setFields, currentMsg, setCurrentMsg, newMsg, getMsgModels, msgModels, setMsgModels }) => {
    const notificationAlertRef = useRef(null);

    const [isPicking, setIsPicking] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);
    const [selectedVar, setSelectVar] = useState("none")
    const [selectedModel, setSelectModel] = useState("none")
    const [drag, setDrag] = useState(false);
    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });

    const onChange = (value, name) => {
        setCurrentMsg({...currentMsg, [name]: value, type: "text"})
    }

    const getSel = ( value) =>  {
        var txtarea = document.getElementById("whatsapp-editor");
        var start = txtarea.selectionStart;
        console.log("start:",start);
        var finish = txtarea.selectionEnd;
        var allText = txtarea.value;
        var sel = allText.substring(start, finish);
        console.log("sel:",sel);
        if(sel=="") return
        var newText = allText.substring(0, start) + `${value}` + sel + `${value}` + allText.substring(finish, allText.length);
        setCurrentMsg({...currentMsg, texto: newText, type: "text"})
    }
    
    const setBold = () => {
        getSel('**')
    }

    const setItalic = () => {
        getSel('_')
    }

    const setStrikethrough = () => {
        getSel('~')
    }

    const onChangeFile = (file) => {
        console.log("file:",file);
        setCurrentMsg({...currentMsg, media: {
            has: true,
            type: file.type.split("/")[0],
            file: file,
            origin: "local"
        }});
    }
    
    const setVar = (value) => {
        let _var = false;
        fields.columns.map(i=>{
            if( parseInt(i.index) == parseInt(value) ){
                _var = i.var;
            }
        })
        setCurrentMsg({...currentMsg, texto: currentMsg.texto + `{{${_var}}}`, type: "text"})
        setSelectVar("none")
    }
    
    const setModel = (value) => {
        let _var = false;
        msgModels.map(i=>{
            if(i.id == value){
                _var = i;
            }
        })
        setFields({
            ...fields,
            id: _var.id,
            method: 'edit',
            msgTitle: _var.name,
            mensagens: _var.mensagens
        })
        setSelectModel("none")
    }
    
    const onEmojiClick = (event, emojiObject) => {
        setCurrentMsg({...currentMsg, texto: currentMsg.texto + emojiObject.emoji, type: "text"})
        setIsPicking(!isPicking)
    };

    const notify = (text, type) => {
        var options = {};
        options = {
          place: "tr",
          message: (
            <div>
              <div>
                {text}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };




    const deleteModel = async (id) => {
        if(!id) return
        try {
            const res = await Api("post", "message/del", {id}, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            console.log("deleteModel:",res.data);
            getMsgModels();
            setSelectModel("none");
            notify("Modelo Deletado com Sucesso!")
        } catch (error) {
            console.log("error:",error);
        }
    } 

    const toggle = () => setModal({...modal, status: !modal.status});

    // useEffect(()=>{
    //     setFields({...fields, msgTitle: e.target.value});
    // },[selectedModel])

    useEffect(()=>{
        if(!msgModels) getMsgModels();
    },[msgModels])
    return (
        <EditorBlock>
            <ReactNotificationAlert ref={notificationAlertRef} />
            <Modal isOpen={modal.status} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {modal.text}
                </ModalBody>
                <ModalFooter style={{padding: "24px"}}>
                    <Button color="primary" onClick={()=>{
                        modal.onOk(modal.id ? modal.id : false);
                        setModal({
                            status: false,
                            text: "",
                            buttonOk: "",
                            onOk: false
                        })
                    }}>{modal.buttonOk}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
                           
            <EditorPanel>
                <IconBlock onClick={()=>setBold()}><BsTypeBold/></IconBlock>
                <IconBlock onClick={()=>setItalic()}><BsTypeItalic/></IconBlock>
                <IconBlock onClick={()=>setStrikethrough()}><BsTypeStrikethrough/></IconBlock>
                <IconBlock isPicking={isPicking} onClick={()=>setIsPicking(!isPicking)}><BsEmojiSmile/></IconBlock>
                <IconBlock isPicking={isSelecting} onClick={()=>setIsSelecting(!isSelecting)}><VscFileSubmodule/></IconBlock>
                <SelectVarBlock style={{margin: "0 4px"}}>
                    <Input type="select" value={selectedVar} onChange={(e) => setSelectVar(e.target.value)} >
                        <option value="none">Variáveis</option>
                        {
                            fields.columns.map( i => (
                                i.use && i.var != "" ? <option key={"vars-"+i.index} value={i.index}> {i.var} </option> : null
                            ))
                        }
                    </Input>
                    {
                        selectedVar != "none" && <Button onClick={()=>setVar(selectedVar)} size="sm" color="primary">Adicionar</Button>
                    }
                </SelectVarBlock>
                <SelectVarBlock  style={{margin: "0 4px"}}>
                    <Input type="select" value={selectedModel} onChange={(e) => setSelectModel(e.target.value)} >
                        <option value="none">Modelos</option>
                        {
                            msgModels &&
                            msgModels.map(i => (
                               <option key={i.id} value={i.id}> {i.name} </option>
                            ))
                        }
                    </Input>
                    {
                        selectedModel != "none" && 
                        <>
                            <Button className="mx-2" onClick={()=>setModel(selectedModel)} size="sm" color="primary">Adicionar</Button>
                            <Button className="mx-2" onClick={ () => {
                                setModal({
                                    status: true,
                                    text: "Deseja Deletar esta Modelo?",
                                    buttonOk: "Deletar",
                                    id: selectedModel,
                                    onOk: deleteModel
                                })
                            }} size="sm" color="danger">Excluir</Button>
                        </>
                    }
                </SelectVarBlock>
                {
                    currentMsg.media.has &&
                    <IconBlockPickMedia>
                        <span><span className="current-media">{currentMsg.media.origin == "local" ? currentMsg.media.file.name : currentMsg.media.filename }</span> <AiOutlineCloseCircle onClick={()=>{
                            setCurrentMsg({...currentMsg, media: {
                                has: false,
                                type: ""
                            }})
                        }}/> </span>
                    </IconBlockPickMedia>
                }
                
            </EditorPanel>
            <EditorTextBlock>
                <EditorText id="whatsapp-editor" onChange={(e)=>onChange(e.target.value, 'texto')} value={currentMsg.texto} rows={12}/>
                {
                    isSelecting &&
                    <FileSelector>
                        <section>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 25,
                                border: drag ? "1px dashed green"  : `1px dashed #f5f5f5`,
                                
                            }} onDragOver={(e)=>{
                                let event = e ;
                                event.stopPropagation();
                                event.preventDefault();
                                setDrag(true)
                            }} onDragLeave={()=>{
                                setDrag(false)
                            }}

                            onDrop={(e)=>{
                                var file = e.dataTransfer.items[0].getAsFile();
                                e.stopPropagation();
                                e.preventDefault();
                                setDrag(false)
                                onChangeFile(file)
                                setIsSelecting(!isSelecting)
                            }}>
                                <span
                                    style={{
                                        color: "#fff"
                                    }}>{drag ? "Soltar" : "Solte um Arquivo Aqui"}</span>
                            </div>
                        </section>
                    </FileSelector>
                }
                {
                    isPicking &&
                    <EmojiPickerBlock>
                        <EmojiPickerBox>
                            <PickerEmoji onEmojiClick={onEmojiClick} />
                        </EmojiPickerBox>
                    </EmojiPickerBlock>
                }
            </EditorTextBlock>
        </EditorBlock>
    )
}

export default memo(WhatappTextEditor)