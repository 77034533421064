import styled from "styled-components";







export const TextEditor = styled.div`
    width: 100%;
    border: 1px solid #2b3553;
    padding: 12px;
`;
export const TextEditorPanel = styled.section`
    width: 100%;
    display: flex;
    justify-content: left;
    border-bottom: 1px solid #2b3553;
`;

export const EmojiPickerBlock = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0, .3);
`;

export const EditorTextBlock = styled.section`
    position: relative;
`;
export const FileSelector = styled.div`
  
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0, .3);
    padding: 24px;
    section {
        width: 100%;
    }
`;
export const EditorTextarea = styled.textarea`
    width: 100%;
    background-color: rgba(0,0,0, .0);
    border: none;
    padding: 16px;
    color: #fff;
    font-size: 18px;
    :focus {
        border: none;
        outline: none;
    }
`;

export const IconBlock = styled.div`
    width: 64px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${p => p.isPicking ? "#d058c7" :"none"};
    color: ${p => p.isPicking ? "#fff" :"none"};
    cursor: pointer;
    z-index: 99;
    :hover {
        background-color: #2b3553;
    }
    .emoji-picker-react {
        z-index: 99;
    }
    svg {
        font-size: 20px;
    }
`;
export const EmojiPickerBox = styled.div`
`;
