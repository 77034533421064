import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, PopoverBody, PopoverHeader, Row, Table, UncontrolledPopover, Modal, ModalBody, ModalFooter, ModalHeader, CardTitle,} from "reactstrap";
import { memo, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TableColumnSelectClient from "components/TableColumnSelectClient";
import SaveContact from "components/SaveContact";
import Api from "services/api";
import Paginate from "components/Paginate";
import { AuthContext } from "contexts/AuthContext";
import { useHistory } from "react-router-dom";
import ContactSendedList from "components/ContactsSendedList";
import { Progress } from "components/SaveContact/style";
import { ProgressBar } from "components/SaveContact/style";
import * as XLSX from "xlsx";
import ReactNotificationAlert from "react-notification-alert";

import {HiChevronDown} from "react-icons/hi"
import { AgeSearch } from "components/ContactSearch";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

const indexies = {
    name: {
        name: "NOME",
        key: 'name',
        use: true,
        usecol: true,
        Search: Input,
        index: false
    },
     
    birthdate: {
        name: "DATA NASCIMENTO",
        key: 'birthdate',
        usecol: true,
        use: true,
        Search: AgeSearch,
        index: false
    },
    client_cpf: {
        name: "CPF",
        key: "client_cpf",
        usecol: true,
        use: true,
        Search: Input,
        index: false
    },
    rg: {
        name: "RG",
        usecol: true,
        use: true,
        key: 'rg',
        Search: Input,
        index: false
    },
    genero: {
        name: "GÊNERO",
        usecol: true,
        use: true,
        key: 'genero',
        Search: Input,
        index: false
    },
    name_author: {
        name: "NOME RESPONSÁVEL",
        key: 'name_author',
        usecol: true,
        use: true,
        Search: Input,
        index: false
    },
    cpf_author: {
        name: "CPF RESPONSÁVEL",
        key: 'cpf_author',
        usecol: false,
        use: false,
        Search: Input,
        index: false
    },

    bairro: {
        name: "BAIRRO",
        key: 'bairro',
        usecol: false,
        use: false,
        Search: Input,
        index: false
    },
    cidade: {
        name: "CIDADE",
        key: 'cidade',
        usecol: false,
        use: false,
        Search: Input,
        index: false
    },
    uf: {
        name: "UF (ESTADO)",
        key: 'uf',
        use: false,
        usecol: false,
        Search: Input,
        index: false
    },
    cep: {
        name: "CEP",
        key: 'cep',
        use: false,
        usecol: false,
        Search: Input,
        index: false
    },
    numero: {
        name: "NUMERO DA CASA",
        key: 'numero',
        use: false,
        usecol: false,
        Search: Input,
        index: false
    },
    logradouro: {
        name: "LOGRADOURO",
        key: 'logradouro',
        use: false,
        usecol: false,
        Search: Input,
        index: false
    },
    ddd: {
        name: "DDD",
        key: 'ddd',
        arr: [],
        usecol: false,
        use: false,
        Search: Input,
        index: false
    },
    cel: {
        name: "CELULAR 1",
        key: 'cel',
        arr: [],
        usecol: false,
        use: false,
        Search: Input,
        index: false
    },
    cel2: {
        name: "CELULAR 2",
        key: 'cel2',
        arr: [],
        usecol: false,
        use: false,
        Search: Input,
        index: false
    },
    cel3: {
        name: "CELULAR 3",
        key: 'cel3',
        arr: [],
        usecol: false,
        use: false,
        Search: Input,
        index: false
    },
    cel4: {
        name: "CELULAR 4",
        key: 'cel4',
        arr: [],
        usecol: false,
        use: false,
        Search: Input,
        index: false
    },
    cel5: {
        name: "CELULAR 5",
        key: 'cel5',
        arr: [],
        usecol: false,
        use: false,
        Search: Input,
        index: false
    },
    email: {
        name: "EMAIL",
        key: 'email',
        use: false,
        usecol: false,
        Search: Input,
        index: false
    }
}

const getKeys = (json) => {
    let j = {}
    for (const key in json) {
        j[key] = {
            use: json[key].use
        }
    }
    return j
}
const getKeys2 = (json) => {
    let j = {}
    for (const key in json) {
        j[key] = ""
    }
    return j
}

const mockedDownloadedContacts = {
    total: 0,
    limitQuery: 100,
    page: 0,
    pages: 0,
    limitTotal: 10000,
    lenQuery: 0,
    lenTotal: 0,
    status: 'waiting'
}

global.downloadedContactsList = [];
global.cancelDownloadContactsList = false;

function Clientes(){

    const notificationAlertRef = useRef(null);

    const history = useHistory();
    
    const [downloadedContacts, setDownloadedContacts] = useState(mockedDownloadedContacts);

    const [fields, setFields] = useContext(AuthContext);

    const [contacts, setContacts] = useState({
        queried: false,
        searched: false,
        page: 2,
        limit: 10,
        count: 0,
        list: []
    });

    const [column, setColumn] = useState({
        ...getKeys(indexies)
    })

    const [uploadProgress, setUploadProgress] = useState(0);

    const [upload, setUpload] = useState({
        file: "",
        status: false
    });

    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });

    const notify = (text, type) => {
       
        var options = {};
        options = {
          place: "tr",
          message: (
            <div>
              <div>
                {text}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const [contactFields, setContactFields] = useState({
        indexies: indexies,
        step: 1,
        sendlist: false,
        contactfile: false,
        filesize: 0,
        serverfile: "",
        filename: "",
        filetype: "Bytes",
        filetype: "Bytes",
        cols: [],
        rows: [],
        contacts: [],
        columns: []
    });

    const [search, setSearch] = useState({
        ...getKeys2(indexies),
        minAge: "",
        maxAge: "",
        kms: "",
        geo: false,
        adressType: "adress"
    });

    const [drag, setDrag] = useState(false);
    
    const setIndexies = (indexies, columns) =>{
        setContactFields({...contactFields, indexies, columns})
    }
    
    const setColumns = (cols) =>{
        setContactFields({...contactFields, columns: cols})
    }
    function percentage(partialValue, totalValue) {
        console.log("partialValue:",partialValue);
        console.log("totalValue:",totalValue);
        return (100 * partialValue) / totalValue;
    }

    const resetContactFields = () => {
        setContactFields({
            indexies: indexies,
            step: 1,
            sendlist: false,
            contactfile: false,
            cols: [],
            rows: [],
            contacts: [],
            columns: []
        })
    }
    
    const setStep = (step) =>{
        setContactFields({...contactFields, step})
    }

    const getSearch = (value, name) => {
        if(name === "cel" || name=== "tel" || name=== "client_cpf" || name === "kms" || name == "minAge" || name == "maxAge"){
            value = value.replace(/[^0-9]+/g, "");
        }
        setSearch({...search, [name]: value})
    }

    const canSearch = () => {
        const keys = Object.keys(getKeys2(indexies));
        let can = false;
        for (let i = 0; i < keys.length; i++) {
            if(search[keys[i]]!="") {
                can = true
            }
        }

        if(search.minAge != "" || search.maxAge != "" || search.kms) {
            can = true
        }
        return can
    }

    const parseCols = (cols) => {
        return cols.map((i,k)=>{
            return {
                index: k,
                name: i,
                wpp: false,
                use: false,
                key: "none"
            }
        })
    }

    const createSLXArray = (xls, file) => {
        let columns = [];
        let rows    = [];
        if(xls[0]) {
            const obj = xls[0];
            Object.keys(obj).forEach(key => {
                columns.push(key);
            });
            for (let i = 0; i < xls.length; i++) {
                const row = xls[i];
                let _row = [];
                Object.keys(row ).forEach(key => {
                    _row.push(row[key])
                });
                rows.push(_row);
            }
        }
        setContactFields({...contactFields, contactfile: file, cols: columns, columns: parseCols(columns), rows, contacts: [[...columns], ...rows]});
    }

    const makeContacts = (data) => {
        console.log("data:",data);
        let columns = [];
        let rows    = [];
        for (let i = 0; i < data.length; i++) {
            const client = data[i];
            if(rows.length === 0) {
                let cols = [];
                for (const k in client) {
                    if(indexies[k]) {
                        cols.push(indexies[k].name)
                    }
                }
                columns = cols;
            }
            let _rows = [];
            for (const k in client) {
                if(indexies[k]) {
                    _rows.push(client[k])
                }
            }
            rows.push(_rows);
        }
        console.log("column:",parseCols(columns));
        setFields({...fields, hasContacts: true, contactfile: true, cols: columns, columns: parseCols(columns), rows, contacts: [[...columns], ...rows]});
        history.push("/admin/message")
    }

    const downloadAllContacts = async (page) => {
        if(!page) return
        try {
            const res = await Api("post", `contact/search?full=true&limit=${downloadedContacts.limitQuery}&page=${page}`, {...search}, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data){
                if(!res.data.list || !res.data.list.length) {
                    return
                }
                if(global.cancelDownloadContactsList) {
                    global.cancelDownloadContactsList = false
                    setDownloadedContacts(mockedDownloadedContacts);
                    return
                }
                global.downloadedContactsList = [...global.downloadedContactsList, ...res.data.list];
                setDownloadedContacts({ ...downloadedContacts, page, lenTotal: downloadedContacts.lenTotal + res.data.list.length});
                //makeContacts(res.data.list);
            }
        } catch (error) {
            console.log("SendMsgs error:",error);
        }
    }

    const SendMsgs = async () => {
        const pages = downloadedContacts.limitQuery >= contacts.count ? 1 : Math.ceil(contacts.count / downloadedContacts.limitQuery);
        setModal({...modal, status: true })
        setDownloadedContacts({ ...downloadedContacts, pages, total: contacts.count, status: 'downloading'});
    }

    const toggle = () => setModal({...modal, status: !modal.status});

    const onChangeFile = (file) => {
        let filetype = "Bytes";
        let filesize = file.size;
        if(filesize > 1024) {
            filesize = file.size / 1024;
            filetype = "KB";
        }
        if(filetype == "KB" && filesize > 1024) {
            filesize = filesize / 1024;
            filetype = "MB";
        }

        setContactFields({...contactFields, contactfile: file, filesize: filesize.toFixed(2), filetype, filename: file.name})
        //xlsReader(file);
    }
    const downloadXLS = (cols, rows) => {
        const date = new Date();

        let name = `Relatorio___${getCurrentRelatories()}_de_${getTotalRelatories()}`;
            name += `___${date.getDay()}_${date.getDate()}_${(new Date).getMonth()+1}_${(new Date).getFullYear()}`;
            name += `___${date.getTime()}`;
        const workbook = XLSX.utils.book_new();
        var ws_data = [
            cols,
            ...rows
        ];
        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
        XLSX.writeFile(workbook, name+".xlsx");
    }

    const createRelatory = (list) => {
        const cols = Object.keys(indexies);
        let   rows = [];
        for (let i = 0; i < list.length; i++) {
            const contact   = list[i];
            let   _contact  = []
            for (let x = 0; x < cols.length; x++) {
                if(contact[cols[x]]) {
                    _contact.push(contact[cols[x]])
                } else {
                    _contact.push("nulled");
                }
            }
            rows.push(_contact);
        }
        downloadXLS(cols, rows);
    }

    const searchContacts = async() => {
        try {
            const res = await Api("post", `contact/search?limit=${contacts.limit}&page=${1}`, {...search}, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data){
                setContacts({...res.data, searched: true, queried: true});
            }
        } catch (error) {
            console.log("getContacts error:",error);
            setContacts({...contacts, queried: true});
        }
    }

    const waitUploadReturnCols = async() => {
        await delay(1000);
        try {
            const res = await Api("post", `contact/getcols`, {file: upload.file}, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            console.log("waitUploadReturnCols:",res);
            if(res.data.status === "reading") {
                waitUploadReturnCols();
            } else if(res.data.status === "done") {
                setUpload({...upload,...res.data})
            }
        } catch (error) {
            console.log("getContacts error:",error);
        }
    }

    const sendFile = async () => {
        let form = new FormData();
        form.append("file", contactFields.contactfile)
        const fileSize = contactFields.contactfile.size;
        try {
            const res = await Api("post", `contact/upload`, form, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                },
                onUploadProgress: progressEvent => {
                    setUploadProgress(percentage(progressEvent.loaded, progressEvent.loaded >= fileSize ? progressEvent.loaded : fileSize))
                }
            });
            console.log("sendFile:",res.data);
            if(res.data){
                setUpload(res.data);
            }
        } catch (error) {
            console.log("getContacts error:",error);
        }
    }

    function percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }


    const getContacts = async(page = 1) => {
        if(!contacts.searched){
            try {
                const res = await Api("get", `contact/list?limit=${contacts.limit}&page=${page}`, {
                    headers: {
                        "x-access-token": localStorage.getItem("terces")
                    }
                });
                if(res.data){
                    setContacts({...res.data, queried: true, searched: contacts.searched});
                }
            } catch (error) {
                console.log("getContacts error:",error);
                setContacts({...contacts, queried: true});
            }
        } else {
            try {
                const res = await Api("post", `contact/search?limit=${contacts.limit}&page=${page}`, {...search}, {
                    headers: {
                        "x-access-token": localStorage.getItem("terces")
                    }
                });
                if(res.data){
                    setContacts({...res.data, searched: true, queried: true});
                }
            } catch (error) {
                console.log("getContacts error:",error);
                setContacts({...contacts, queried: true});
            }
        }
    }

    const getCurrentRelatories = () => {
        if(downloadedContacts.total <= downloadedContacts.limitTotal) {
            return 1
        }

        if(downloadedContacts.lenTotal <= downloadedContacts.limitTotal) {
            return 1
        }

        return Math.ceil(downloadedContacts.lenTotal / downloadedContacts.limitTotal);
        
    }

    const getTotalRelatories = () => {
        if(downloadedContacts.total <= downloadedContacts.limitTotal) {
            return 1
        }

        return Math.ceil(downloadedContacts.total / downloadedContacts.limitTotal);
        
    }

    useEffect(()=>{
        if(upload.status==="reading") {
            waitUploadReturnCols();
        } else if(upload.status==="done") {
            setContactFields({...contactFields, cols: upload.cols, columns: parseCols(upload.cols), serverfile: upload.file});
            setUpload({status: false, file: ""})
        }
    },[upload]);

    useEffect(()=>{
        if(!contacts.queried) {
            getContacts();
        }
    },[contacts]);

    useEffect(()=>{
        if(global.cancelDownloadContactsList) {
            setDownloadedContacts({...mockedDownloadedContacts});
            return
        }
        if(downloadedContacts.status == "downloading") {
            console.log("downloadedContacts:",downloadedContacts);
            // if(downloadedContacts.total < downloadedContacts.limitQuery) {
            //     createRelatory();
            //     setDownloadedContacts(mockedDownloadedContacts);
            //     // gerar relátorio final
            // } else 
            
            if(downloadedContacts.total <= downloadedContacts.lenTotal) {
                console.log("GRL 1");
                // gerar relátorio final
                createRelatory([...global.downloadedContactsList]);
                global.downloadedContactsList = [];
                setDownloadedContacts({...mockedDownloadedContacts, status: "complete"});
            } 
            
            else   if(global.downloadedContactsList.length >= downloadedContacts.limitTotal) {
                console.log("GRL 2");

                // gerar relátorio, limpar lista contatos e baixar mais contatos
                if((downloadedContacts.page + 1) <= downloadedContacts.pages) {
                    createRelatory([...global.downloadedContactsList]);
                    global.downloadedContactsList = [];
                    downloadAllContacts(downloadedContacts.page + 1);
                } else {
                    createRelatory([...global.downloadedContactsList]);
                    global.downloadedContactsList = [];
                }
            } 
            
            else {
                console.log("GRL 2");

                if((downloadedContacts.page + 1) <= downloadedContacts.pages) {
                    downloadAllContacts(downloadedContacts.page + 1);
                } else {
                    createRelatory([...global.downloadedContactsList]);
                    global.downloadedContactsList = [];
                }
            }
        }
    },[downloadedContacts]);

    if(contactFields.sendlist) {
        return <ContactSendedList contactFields={contactFields} setContactFields={setContactFields}/>
    }

    if(contactFields.step===1) {
        return (
            <div className="content">
                <ReactNotificationAlert ref={notificationAlertRef} />
                <Modal isOpen={modal.status} toggle={toggle}>
                    <ModalHeader toggle={toggle}></ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <h3 style={{color: "#444"}}>{downloadedContacts.status == "downloading" ? `Baixando Relatórios ${getCurrentRelatories()} de ${getTotalRelatories()}` : downloadedContacts.status == "complete" ? "Relatórios Baixados com sucesso!" : "Baixar Relatórios"}</h3>
                                <h3 style={{color: "#444"}}>{downloadedContacts.status == "downloading" ? percentage(global.downloadedContactsList.length, downloadedContacts.limitTotal).toFixed(2) : downloadedContacts.status == "complete" ? 100 : 0}%</h3>
                                <ProgressBar>
                                    <Progress progress={ downloadedContacts.status == "downloading" ? percentage(global.downloadedContactsList.length, downloadedContacts.limitTotal).toFixed(2) : downloadedContacts.status == "complete" ? 100 : 0}/>
                                </ProgressBar>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{padding: "24px"}}>
                        {
                            downloadedContacts.status == 'waiting' ?
                                <Button color="primary" onClick={()=>SendMsgs()}>Baixar</Button>
                            : null
                        }
                        {
                            downloadedContacts.status == 'complete' ?
                                <Button color="success" onClick={()=>{
                                    setDownloadedContacts(mockedDownloadedContacts);
                                    toggle();
                                }}>Concluir</Button>
                            : null
                        }

{
                        downloadedContacts.status != 'complete' ?
                            <Button color="secondary" onClick={()=> {
                                global.cancelDownloadContactsList = true;
                                toggle();
                            }}>Cancelar</Button>
                            : null
                        }
                        
                        
                    </ModalFooter>
                </Modal>
                    
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <h1>Clientes: {contacts.count}</h1>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col> 
                                        <Button onClick={ () => setContactFields({...contactFields, sendlist: true}) } className="btn-fill" color="warning">Listas enviadas</Button> 
                                        {
                                            (contacts.searched && contacts.count) &&
                                            <Button onClick={ () => toggle() } className="btn-fill" color="info">Gerar relatório desta pesquisa</Button> 
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <Row>
                                                            <Col md="6"></Col>
                                                            <Col>
                                                                <PopoverButton id="PopoverClick"><div>Colunas<span><HiChevronDown/></span></div></PopoverButton>
                                                                <UncontrolledPopover
                                                                    placement="bottom"
                                                                    target="PopoverClick"
                                                                    trigger="click">
                                                                    <PopoverBody>
                                                                        <div style={{
                                                                            maxHeight: 300,
                                                                            overflowY: "auto"
                                                                        }}>
                                                                            <Table>
                                                                                <tbody>
                                                                                    {
                                                                                        Object.keys(indexies).map(i => (
                                                                                            <tr key={"tbody-r-"+i}>
                                                                                                <th>
                                                                                                    <FormGroup check>
                                                                                                        <Label check>
                                                                                                            <input {...(column[i].use ? {checked: true} : {})}
                                                                                                                defaultValue={column[i].use}
                                                                                                                type="checkbox"
                                                                                                                onChange={(e) => setColumn({...column, [i]: {
                                                                                                                    use: !column[i].use,

                                                                                                                }})}
                                                                                                                />
                                                                                                            <span className="form-check-sign">
                                                                                                            </span>
                                                                                                        </Label>
                                                                                                    </FormGroup>
                                                                                                </th>
                                                                                                <th>{indexies[i].name}</th>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </PopoverBody>
                                                                </UncontrolledPopover>
                                                            </Col>
                                                            <Col>
                                                                <Input value={search.kms} onChange={e => getSearch(e.target.value, "kms")} style={ {border: "1px solid #f5f5f5"}} type="text" placeholder={'Distância em km'}/>
                                                            </Col>
                                                            <Col>
                                                                <Button disabled={!canSearch()} color="primary" onClick={ () => searchContacts() }>Pesquisar</Button>
                                                            </Col>
                                                        </Row>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </Table>
                                    </Col>
                                </Row>
                                <p className="mt-1">Pagina: {contacts.page} de {Math.ceil(parseInt(contacts.count) / parseInt(contacts.limit))}</p>
                                <Table>
                                    <thead className="text-primary">
                                    <tr>
                                        {
                                            Object.keys(column).map( i => {
                                                const Search = indexies[i].Search
                                                return (
                                                    column[i].use && 
                                                    <th>
                                                        {
                                                            <Search value={search[i]} search={search} getSearch={getSearch} onChange={e => getSearch(e.target.value, i)}  style={ !search.geo ? {border: "1px solid #f5f5f5"} : {}} type="text" placeholder={"Pesquisar "+indexies[i].name.toLowerCase()}/>
                                                        }
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            Object.keys(column).map( i => (
                                                column[i].use &&
                                                <th key={"thead-name-r-"+i} style={{color: "#f1f1f1"}}>{indexies[i].name}</th>
                                            ))
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {contacts.list.map(contact => (
                                            <tr key={contact._id}>
                                                {
                                                    Object.keys(column).map( i => (
                                                        column[i].use &&
                                                        <td key={"tbody-input-r-"+i+"-"+contact._id} style={{color: "#f1f1f1"}}>{contact[i] && contact[i]}</td>
                                                    ))
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {
                                    contacts.queried &&
                                    <Paginate getContacts={getContacts} setContacts={setContacts} contacts={contacts}/>
                                }
                            </CardBody>
                        </Card>
                    </Col>  
                </Row>
            </div>
        )
    }

    if(contactFields.step === 3) {
        return (
            <div className="content">
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <TableColumnSelectClient setStep={setStep} setIndexies={setIndexies} contactFields={contactFields} setColumns={setColumns}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
    return (
        <SaveContact setContactFields={setContactFields} contactFields={contactFields}/>
    )
}

const IconLoader = styled.span`
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    svg {
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        color: #e14eca;
    }
`;

const InputFile = styled.div`
    padding: 4px 16px;
    label {
        width: 100%;
        height: auto;
    }
    .input-btn {

    }
    .input-txt {
        border: 1px solid rgba(255, 255, 255, .1);
        padding: 4px 12px;
    }
`;

const PopoverButton = styled.div`
    display: inline-block;
    padding: 8px 24px;
    background-color: #fff;
    border-radius: 3px;
    color: #000;
    float: right;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    svg {
        margin-left: 12px;
        font-size: 20px;
    }
`;


const InputBtn = styled.span`
    color: ${p => p.selected ? "#fff" : "#444"};;
    padding: 4px 12px;
    background-color: ${p => p.selected ? "#0e7c1c" : "#fff"};
`;

export default memo(Clientes)