import styled from "styled-components";

export const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoadingBox = styled.span`
    height: 100px;
    margin-top: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    p {
        width: 100%;
        text-align: center;
        margin-top: 24px;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const Text = styled.p`
    
`;

export const LoadingIcon = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #ce56c7;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #ce56c7 transparent transparent transparent;
    }
    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }

`;
