import { memo } from "react"
import { Box } from "./style";

const PhoneNotConnected = () => {
    return (
        <Box>
            <h1>Celuar não Conectado</h1>
        </Box>
    )
}


export default memo(PhoneNotConnected);