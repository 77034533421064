import { useEffect, useRef, useState } from "react";
import ReactNotificationAlert from "react-notification-alert";
import { Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import Api from "services/api";



import { Badge, InfoTitle, ModalTable } from "./style";
const delay = ms => new Promise(resolve => setTimeout(resolve, ms))
const status = {
    waiting: {
        text: "Iniciando conversão de endereços ",
        color: "darkcyan"
    },
    complete: {
        text: "Endereços convertidos",
        color: "green"
    },
    creating: {
        text: "Criando banco de dados",
        color: "skyblue"
    },
    creatingdb: {
        text: "Criando banco de dados",
        color: "skyblue"
    },
    converting: {
        text: "Transformando endereços em coordenadas",
        color: "purple"
    }
}

export default function ContactSendedList(){

    const notificationAlertRef = useRef(null);

    const [sended, setSended] = useState({
        status: false,
        limit: 20,
        page: 1,
        list: []
    })

    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });

    const [currentContact, setCurrentContact] = useState(false);

    const getSended = async (page = 1) => {
        try {
            const res = await Api("get", `contact/sendedlist?limit=${sended.limit}&page=${page}`, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            console.log("res.data:",res.data);
            if(res.data){
                setSended({...sended, ...res.data, status: true});
            }
        } catch (error) {
            console.log("getSended error:",error);
        }
    }

    const getContact = async (id) => {

        console.log("current id:", id);
        try {
            const res = await Api("get", `contact/getlist?id=${id}`, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            console.log("res.data:",res.data);
            if(res.data){
                setCurrentContact(res.data);
            }
        } catch (error) {
            console.log("getSended error:",error);
        }
    }

    const deleteSended = async (id) => {
        try {
            const res = await Api("post", `contact/deletelist`, {id}, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(id==currentContact._id) {
                setCurrentContact(false)
            }
            getSended()
        } catch (error) {
            console.log("getSended error:",error);
        }
    }

    const toggle = () => setModal({...modal, status: !modal.status});


    const notify = (text, type) => {
       
        var options = {};
        options = {
          place: "tr",
          message: (
            <div>
              <div>
                {text}
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    useEffect(()=>{
        const timer = setInterval(() => {
            if(currentContact) {
                getContact(currentContact._id);
            }
        }, 2000);

        return () => {clearInterval(timer)}
    },[currentContact])

    useEffect(()=>{
        if(!sended.status){
            getSended();
        }
    },[sended]);
    console.log("currentContact:",currentContact);
    return (
        <div className="content">
            <ReactNotificationAlert ref={notificationAlertRef} />
            <Modal isOpen={modal.status} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {modal.text}
                </ModalBody>
                <ModalFooter style={{padding: "24px"}}>
                    <Button color="primary" onClick={()=>{
                        modal.onOk(modal.id);
                        setModal({
                            status: false,
                            text: "",
                            buttonOk: "",
                            onOk: false
                        })
                    }}>{modal.buttonOk}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Table>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Nome do Arquivo</th>
                                        <th>Data</th>
                                        <th>Contatos</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sended.list.map((i, k)=>(
                                            <tr key={"tl-s-"+k}>
                                                <td>{i.name && i.name}</td>  
                                                <td>{(new Date(i.createdAt)).toLocaleDateString()}</td>  
                                                <td>{i.contacts && i.contacts}</td>
                                                <td><Badge color={i.status && status[i.status].color}>{i.status && status[i.status].text}</Badge></td>
                                                <td>
                                                    <Button color="danger" onClick={()=>{
                                                        setModal({
                                                            status: true,
                                                            text: "Deseja deletar esta lista de contatos?",
                                                            buttonOk: "Deletar",
                                                            id: i._id,
                                                            onOk: deleteSended
                                                        })
                                                    }}>Deletar</Button>
                                                </td>
                                                <td>
                                                    <Button color="primary" onClick={()=>{
                                                        getContact(i._id);
                                                    }}>Ver</Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                {
                    currentContact &&
                    <Col md="4">
                        <Card>
                            <CardHeader>
                                <h2>Informações</h2>
                            </CardHeader>
                            <CardBody>
                               
                                
                                 
                                <h5>status: <Badge style={{marginLeft: 12}} color={status[currentContact.status].color}>{status[currentContact.status].text}</Badge></h5>
                               
                                <Row>
                                   
                                    {
                                        currentContact.status == "creating" || currentContact.status == "creatingdb" || currentContact.status == "waiting" ?
                                            <Col>
                                                <InfoTitle>Criando Contatos: <span>{currentContact.createdcontacts}</span></InfoTitle>
                                            </Col>
                                        :
                                            <>
                                                <Col>
                                                    <InfoTitle>Contatos: <span>{currentContact.createdcontacts ? currentContact.createdcontacts : '0'}</span></InfoTitle>
                                                </Col>
                                                <Col>
                                                    <InfoTitle>Coordenadas criadas: <span>{currentContact.addressConverteds}</span></InfoTitle>
                                                </Col>
                                            </>
                                    }
                                    
                                </Row>

                                
                            </CardBody>
                        </Card>
                    </Col>
                }
                    
            </Row>
        </div>
    )
}