/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Chat from "views/Chat";
import MensagensPage from "views/Mensagens";
import MsgModel from "views/MsgModel";


var routes = [
  {
    path: "/message",
    name: "Mensagens",
    rtlName: "Mensagens",
    icon: "tim-icons icon-email-85",
    component: Chat,
    layout: "/admin"
  },
  {
    path: "/messages",
    name: "Mensagens Enviadas",
    rtlName: "Mensagens Enviadas",
    icon: "tim-icons icon-email-85",
    component: MensagensPage,
    layout: "/admin"
  },
  {
    path: "/model-messages",
    name: "Modelos de Mensagens",
    rtlName: "Modelos de Mensagens",
    icon: "tim-icons icon-email-85",
    component: MsgModel,
    layout: "/admin"
  },
];
export default routes;
