import { AuthContext } from "contexts/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";
import ReactNotificationAlert from "react-notification-alert";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Label, Row, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from "xlsx";

import Api from "services/api";
import WeeksCheck from "components/WeeksCheck";
import MonthsCheck from "components/monthsCheck";

const months = {
    jan: false,
    fev: false,
    mar: false,
    abr: false,
    mai: false,
    jun: false,
    jul: false,
    ago: false,
    set: false,
    out: false,
    nov: false,
    dez: false,
}
  

const weeks = {
    week0: false,
    week1: false,
    week2: false,
    week3: false,
    week4: false,
    week5: false,
    week6: false,
}

const configModel = {
    address: "",
    address: "",
    addressStatus: "",
    sendMsgInterval: "",
    can_autocreate: false,
    can_autocreate_geo: false,
    can_autoedit: false,
    remote_auth: false,
    account_valid: false,
    facebook_valid: false,
    autoGeoMsg: false,
    facebook_code: "",
    pauseMsgAutoSend: false,
    pauseMsgSend: false,
    coordinates: "",
    account_id: "",
    account_user: "",
    account_pass: "",
    facebook_user: "",
    facebook_pass: "",
    autoMsgId: "",
    dailyFirstMsg: "",
    dailySecondMsg: "",
    msgmodels: [],
    birthDayDailyId:"",
    birthDayMonthlyId:"",
    billsToReceiveId: "",
    ortoToDayId:"",
    ortoNextDayId:"",
    ortoLastSevenDaysId: "",
    ortoLastTwoMonthsId: "",
    autoGeoId: "",
    autoGeoKms: "",
    ortoConvencionalAutoligadoMonths: months,

    ortoConvencionalAutoligadoId: "",
    ortoClinicoGeralId: "",
    ortoPacienteFelizId: "",


    lastBillsToReceive28Id: "",
    ortoManutencaoAutoId: "",
    ortoAdesaoOnDemandId: "",
    ortoLentesId: "",
    ortoFgtsId: "",
    ortoGengivoplastiaId: "",
    ortoProtocolId: "",
    ortoLimpezaCoroaId: "",
    ortoLimpezaId: "",
    ortoClinicoOnDemandId: "",

    fidelityMsg1Id: "",
    fidelityMsg2Id: "",
    fidelityMsg3Id: "",
    fidelityMsg4Id: "",
    ortoNextTwoDayId: "",

    ortoNextTwoDayWeeks: weeks,
    ortoClinicoOnDemandWeeks: weeks,
    ortoAdesaoOnDemandWeeks: weeks,
    ortoConvencionalAutoligadoWeeks: weeks,
    ortoClinicoGeralWeeks: weeks,
    ortoFgtsWeeks: weeks,
    ortoPacienteFelizWeeks: weeks,
    
    fidelityMsg1Weeks: weeks,
    fidelityMsg2Weeks: weeks,
    fidelityMsg3Weeks: weeks,
    fidelityMsg4Weeks: weeks,

    ortoManutencaoAutoWeeks: weeks,
    birthDayDailyWeeks: weeks,
    birthDayMonthlyWeeks: weeks,
    ortoToDayWeeks: weeks,
    ortoNextDayWeeks: weeks,
    ortoLastSevenDaysWeeks: weeks,
    autoGeoWeeks: weeks,
    ortoConvencionalAutoligadoDay: 0,
    ortoLentesWeeks: weeks,
    ortoGengivoplastiaWeeks: weeks,
    ortoProtocolWeeks: weeks,
    ortoLimpezaCoroaWeeks: weeks,
    ortoLimpezaWeeks: weeks,
    msgtime: {
        limit: {
            min: "",
            max: "",
        },
        weeks: weeks
    },
    wpp_active: false
}

const addressStatus = {
    waiting: {
        text: "Verificando Endereço",
        color: "orange"
    },
    valid: {
        text: "Endereço válido",
        color: "green"
    },
    invalid: {
        text: "Endereço inválido",
        color: "red"
    }
}

let lastConfig = {};

export default function Config(){
    const notificationAlertRef = useRef(null);
    const [auth, setAuth] = useContext(AuthContext);
    const [facebookTrycode, setFacebookTrycode] = useState(false);
    const [config, setConfig] = useState({
        ...configModel,
        status: false
    })
    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });

    const [relatorie, setRelatorie] = useState({
        queried: false,
        data: {}
    })

 
    const parseResultToModel = (data) => {
        lastConfig = data;
        let parsed = {}
        for (const key in configModel) {
            if(data.hasOwnProperty(key)) {
                if(key=="coordinates") {
                    lastConfig[key] = data[key].toString();
                    parsed[key] = data[key].toString();
                    continue
                }
                parsed[key] = data[key]
            }
        }
        setConfig({...config,
            status: true, 
            ...parsed
        })
    }
    
    const parseFieldsToModel = (data) => {
        let parsed = {}
        for (const key in configModel) {
            if(typeof data[key] == "object") {
                parsed[key] = data[key]
                if(typeof data[key] == "object") continue
            }
            if(data[key] != lastConfig[key]) {
                if(key=="coordinates") {
                    parsed[key] = data[key].split(",").length == 2 ? data[key].split(",") : [0,0]
                    parsed[key][0] = parseFloat(parsed[key][0])
                    parsed[key][1] = parseFloat(parsed[key][1])
                    continue
                }
                parsed[key] = data[key]
            }
        }
        if(parsed["coordinates"]) {
            parsed["address"] = ""
        }
        if(parsed["address"]) {
            parsed["coordinates"] = [0,0]
        }

        return Object.keys(parsed).length ? parsed : false
    }

    const saveConfig = async () => {
        const data = parseFieldsToModel(config);
        if(!data) return
        const toast_id = toast.loading("Salvando configurações...")
        try {
            const res = await Api("post", "config/set", data, 
            {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                parseResultToModel(res.data);
                toast.update(toast_id, { render: "Configurações salva com sucesso!", type: "success", isLoading: false, autoClose: 5000});
            }
        } catch (error) {
            toast.update(toast_id, { render: "Erro ao salvar as configurações", type: "danger", isLoading: false, autoClose: 5000});
            console.log("error:",error);
        }    
    }

    const getConfig = async () => {
        try {
            const res = await Api("get", "config/get", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            setAuth({
                ...auth,
                config: res.data
            })
            parseResultToModel(res.data);
        } catch (error) {
            console.log("error:",error);
        }    
    }

    const getFbLogout = async () => {
        const toast_id = toast.loading("Saindo do facebook...")
        try {
            await Api("get", "config/facebook-logout", {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
           
            getConfig();
            toast.update(toast_id, { render: "Deslogado do facebook com sucesso!", type: "success", isLoading: false, autoClose: 5000});

        } catch (error) {
            console.log("error:",error);
        }    
    }

    const getFbCode = async () => {
        const toast_id = toast.loading("Enviando código...")
        try {
            const res = await Api("post", "config/facebook-trycode", {code: config.facebook_code}, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data.code_valid) {
                toast.update(toast_id, { render: "Logado no facebook com sucesso!", type: "success", isLoading: false, autoClose: 5000});
                getConfig();
                return
            }
            toast.update(toast_id, { render: "Código inválido!", type: "danger", isLoading: false, autoClose: 5000});
        } catch (error) {
            console.log("error:",error);
        }    
    }

    const getFbLogin = async () => {
        const toast_id = toast.loading("Tentando logar no facebook...")
        try {
            const res = await Api("post", "config/facebook-login", {
                facebook_user: config.facebook_user,
                facebook_pass: config.facebook_pass,
            }, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data.need_code) {
                toast.update(toast_id, { render: "Insira o código de autenticação de 2 fatores", type: "warning", isLoading: false, autoClose: 8000});
                setFacebookTrycode(true);
                return
            }
            toast.update(toast_id, { render: "Login efetuado com sucesso!", type: "success", isLoading: false, autoClose: 5000});
            getConfig();
        } catch (error) {
            console.log("error:",error);
        }    
    }

    const getRelatorie = async () => {
        try {
            const res = await Api("post", "config/atendente-relatory", {
                method: 'get'
            }, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                setRelatorie({queried: true, data: res.data})
            }
        } catch (error) {
            console.log('====================================');
            console.log("error:",error);
            console.log('====================================');
        }
        setTimeout(() => {
            getRelatorie();
        }, 2000);
    }
    
    const handleRelatory = (data) => {
        const workbook = XLSX.utils.book_new();
        let date = new Date();
        const hoje = date.toLocaleDateString();
        date.setDate(date.getDate() - 1);
        const ontem = date.toLocaleDateString();
        
        let cols =  [
            'paciente',
            'atendente',
            'cel',
            'registro'
        ];
        

        let rows = [];
        const rels = Object.values(data.data);
        for (let i = 0; i < rels.length; i++) {
            let row = [];
            row[0] = rels[i].paciente;
            row[1] = rels[i].atendente;
            row[2] = rels[i].cel || "";
            row[3] = ( new Date(rels[i].createdAt)).toLocaleDateString();
            rows.push(row);
        }
        var ws_data = [
            cols,
            ...rows
        ];
        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
        XLSX.writeFile(workbook, "Relatorio de atendentes"+".xlsx");
    }

    const getAtendenteRelatory = async (method) => {
        try {
            const res = await Api("post", "config/atendente-relatory", {
                method,
            }, {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
       
            if(method === 'current_download') {
                handleRelatory(res.data)
            }
        } catch (error) {
        }
    }

    const toggle = () => setModal({...modal, status: !modal.status});

    const onChange = async (value, name) => {
        if(name=="sendMsgInterval") {
            value = value.replace(/[^\d]/g, "")
        }
        setConfig({...config, [name]: value})
    }
    

    const setWeek = (key, week, mode) => {
        console.log({key, week, mode});
        const newConfig = {...config};
        newConfig[key][week] = mode
        setConfig(newConfig)
    }

    const setMonth = (key, month, mode) => {
        console.log({key, month, mode});
        const newConfig = {...config};
        newConfig[key][month] = mode
        setConfig(newConfig)
    }

    useEffect(()=>{
        if(!relatorie.queried){
            getRelatorie();
        }
    },[relatorie]);

    useEffect(()=>{
        if(!config.status){
            getConfig();
        }
    },[config])

    return (
        <div className="content">
             <Modal isOpen={modal.status} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {modal.text}
                </ModalBody>
                <ModalFooter style={{padding: "24px"}}>
                    <Button color="primary" onClick={()=>{
                        modal.onOk(modal.id);
                        setModal({
                            status: false,
                            text: "",
                            buttonOk: "",
                            onOk: false
                        })
                    }}>{modal.buttonOk}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            <ToastContainer/>
            <Row>
                <Col md="6">
                    <Card>
                        <CardHeader>
                            <h3>Configurações</h3>
                        </CardHeader>
                        <CardBody>
                            <h4>
                                {
                                    relatorie.queried && relatorie.data.status === "checking" ? 
                                        `Gerando relatório: ${relatorie.data.count} de ${relatorie.data.total}` 
                                    : ''
                                }
                                {
                                    relatorie.queried && relatorie.data.status === "completed" ? 
                                        `Gerando relatório gerado com sucesso` 
                                    : ''
                                }
                            </h4>
                            <div className="d-flex justify-content-start mb-4">
                                <Button disabled={!!(relatorie.queried && relatorie.data.status === "checking")} color="primary" onClick={()=>getAtendenteRelatory('current_create')}>gerar relatório de atentente (Mês atual)</Button>
                                <Button disabled={!!(!relatorie.queried || relatorie.data.status != "completed")} color="info" onClick={()=>getAtendenteRelatory('current_download')}>
                                    Baixar {
                                        relatorie.queried && relatorie.data.status === "completed" ? (new Date(relatorie.data.date)).toLocaleDateString()  : ''
                                    }
                                </Button>
                            
                            </div>

                            {/* <div className="d-flex justify-content-between mb-4">
                                
                                <Button color="primary" onClick={()=>getAtendenteRelatory('previous_create')}>Baixar relatório de atentente (Mês anterior)</Button>
                                <Button color="primary" onClick={()=>getAtendenteRelatory('previous')}>Baixar</Button>
                                
                            </div> */}
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{marginBottom: 8}}>Coordenadas</Label>
                                        <Input type="text" value={config.coordinates} onChange={(e)=>onChange(e.target.value, "coordinates")} placeholder=""/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{marginBottom: 8}}>Kms</Label>
                                        <Input type="text" value={config.autoGeoKms} onChange={(e)=>onChange(e.target.value, "autoGeoKms")} placeholder=""/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label>Intervalo entre cada mensagem (em segundos)</Label>
                                <Input type="text" value={config.sendMsgInterval} onChange={(e)=>onChange(e.target.value, "sendMsgInterval")} placeholder="0"/>
                            </FormGroup>
                 
                            <h3 className="mt-5">Sistema</h3>
                            <Row>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check className="mx-1 mt-3">
                                            Criar mensagens de geolocalização
                                            <Input
                                                type="checkbox"
                                                name="wpp_active"
                                                checked={config.can_autocreate_geo}
                                                onClick={() => {
                                                    setConfig({...config, can_autocreate_geo: !config.can_autocreate_geo});
                                                }}
                                                />
                                                <span className="form-check-sign">
                                                </span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                          
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check className="mx-1 mt-3">
                                            Criar tratamento de orto
                                            <Input
                                                type="checkbox"
                                                name="wpp_active"
                                                checked={config.can_autocreate}
                                                onClick={() => {
                                                    setConfig({...config, can_autocreate: !config.can_autocreate});
                                                }}
                                                />
                                                <span className="form-check-sign">
                                                </span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                          
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check className="mx-1 mt-3">
                                            Mudar a data de retorno do paciente de orto
                                            <Input
                                                type="checkbox"
                                                name="wpp_active"
                                                checked={config.can_autoedit}
                                                onClick={() => {
                                                    setConfig({...config, can_autoedit: !config.can_autoedit});
                                                }}
                                                />
                                                <span className="form-check-sign">
                                                </span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                          
                            </Row>



                            <h3 className="mt-5">Whatsapp</h3>
                            <Row>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check className="mx-1 mt-3">
                                            Whatsapp ativo
                                            <Input
                                                type="checkbox"
                                                name="wpp_active"
                                                checked={config.wpp_active}
                                                onClick={() => {
                                                    setConfig({...config, wpp_active: !config.wpp_active});
                                                }}
                                                />
                                                <span className="form-check-sign">
                                                </span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                          
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check className="mx-1 mt-3">
                                            Salvar login no banco de dados
                                            <Input
                                                type="checkbox"
                                                name="wpp_active"
                                                checked={config.remote_auth}
                                                onClick={() => {
                                                    setConfig({...config, remote_auth: !config.remote_auth});
                                                }}
                                                />
                                                <span className="form-check-sign">
                                                </span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                          
                            </Row>
                            

                            <h3 className="mt-5">Envio de mensagens</h3>
                            <Row>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check className="mx-1 mt-3">
                                            Pausar o envio de mensagens
                                            <Input
                                                type="checkbox"
                                                name="pauseMsgSend"
                                                checked={config.pauseMsgSend}
                                                onClick={() => {
                                                    setConfig({...config, pauseMsgSend: !config.pauseMsgSend});
                                                }}
                                                />
                                                <span className="form-check-sign">
                                                </span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                          
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup check>
                                        <Label check className="mx-1 mt-3">
                                            Pausar o envio de mensagens de geolocalização
                                            <Input
                                                type="checkbox"
                                                name="autoGeoMsg"
                                                checked={!config.autoGeoMsg}
                                                onClick={() => {
                                                    setConfig({...config, autoGeoMsg: !config.autoGeoMsg});
                                                }}
                                                />
                                                <span className="form-check-sign">
                                                </span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                                    
                            <FormGroup check>
                                <h5 className="mt-5">Dias da semana</h5>
                                <Label check className="mx-1 mt-3">
                                    Domingo
                                    <Input
                                        type="checkbox"
                                        name="week0"
                                        checked={config.msgtime.weeks.week0}
                                        onClick={() => {
                                            setConfig({...config, msgtime: { ...config.msgtime, weeks: {...config.msgtime.weeks, week0: !config.msgtime.weeks.week0}}});
                                        }}
                                        />
                                        <span className="form-check-sign">
                                        </span>
                                </Label>
                            
                                <Label check className="mx-1 mt-3">
                                    Segunda-feira
                                    <Input
                                        type="checkbox"
                                        name="week1"
                                        checked={config.msgtime.weeks.week1}
                                        onClick={() => {
                                            setConfig({...config, msgtime: { ...config.msgtime, weeks: {...config.msgtime.weeks, week1: !config.msgtime.weeks.week1}}});
                                        }}
                                        />
                                        <span className="form-check-sign">
                                        </span>
                                </Label>
                                <Label check className="mx-1 mt-3">
                                    Terça-feira
                                    <Input
                                        type="checkbox"
                                        name="week2"
                                        checked={config.msgtime.weeks.week2}
                                        onClick={() => {
                                            setConfig({...config, msgtime: { ...config.msgtime, weeks: {...config.msgtime.weeks, week2: !config.msgtime.weeks.week2}}});
                                        }}
                                        />
                                        <span className="form-check-sign">
                                        </span>
                                </Label>
                                <Label check className="mx-1 mt-3">
                                    Quarta-feira
                                    <Input
                                        type="checkbox"
                                        name="week3"
                                        checked={config.msgtime.weeks.week3}
                                        onClick={() => {
                                            setConfig({...config, msgtime: { ...config.msgtime, weeks: {...config.msgtime.weeks, week3: !config.msgtime.weeks.week3}}});
                                        }}
                                        />
                                        <span className="form-check-sign">
                                        </span>
                                </Label>
                                <Label check className="mx-1 mt-3">
                                    Quinta-feira
                                    <Input
                                        type="checkbox"
                                        name="week4"
                                        checked={config.msgtime.weeks.week4}
                                        onClick={() => {
                                            setConfig({...config, msgtime: { ...config.msgtime, weeks: {...config.msgtime.weeks, week4: !config.msgtime.weeks.week4}}});
                                        }}
                                        />
                                        <span className="form-check-sign">
                                        </span>
                                </Label>
                                <Label check className="mx-1 mt-3">
                                    Sexta-feira
                                    <Input
                                        type="checkbox"
                                        name="week5"
                                        checked={config.msgtime.weeks.week5}
                                        onClick={() => {
                                            setConfig({...config, msgtime: { ...config.msgtime, weeks: {...config.msgtime.weeks, week5: !config.msgtime.weeks.week5}}});
                                        }}
                                        />
                                        <span className="form-check-sign">
                                        </span>
                                </Label>
                                <Label check className="mx-1 mt-3">
                                    Sábado
                                    <Input
                                        type="checkbox"
                                        name="week6"
                                        checked={config.msgtime.weeks.week6}
                                        onClick={() => {
                                            setConfig({...config, msgtime: { ...config.msgtime, weeks: {...config.msgtime.weeks, week6: !config.msgtime.weeks.week6}}});
                                        }}
                                        />
                                        <span className="form-check-sign">
                                        </span>
                                </Label>
                            </FormGroup>
                   
           
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="min-date-time">
                                            Enviar mensagens apartir de:
                                        </Label>
                                            <Input
                                                onChange={(e)=> setConfig({...config, msgtime: {...config.msgtime, limit: {min: e.target.value, max: config.msgtime.limit.max}}})} 
                                                id="min-date-time"
                                                value={config.msgtime.limit.min}
                                                name="min-time"
                                                placeholder="time placeholder"
                                                type="time"
                                                />
                                        
                                            
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <Label for="maxdate-time">
                                        Até as:
                                    </Label>
                                        <Input
                                            onChange={(e)=> setConfig({...config, msgtime: {...config.msgtime, limit: {max: e.target.value, min: config.msgtime.limit.min}}})} 
                                            id="max-date-time"
                                            value={config.msgtime.limit.max}
                                            min={config.msgtime.limit.min}
                                            name="max-time"
                                            placeholder="time placeholder"
                                            type="time"
                                            />
                                </Col>
                            </Row>
                            <h3 className="mt-4">Mensagens Automáticas</h3>
                            <Row className="my-4">
                                <Col md="4">
                                    {
                                        config.account_valid ?
                                            <span style={{padding: 8, borderRadius: "10px", backgroundColor: "green", color: "white"}}>Login Válido</span>
                                        : 
                                            <span style={{padding: 8, borderRadius: "10px", backgroundColor: "red", color: "white"}}>Login inválido</span>
                                    }
                                </Col>    
                            </Row>
                            <Row className="mb-5">   
                                <Col md="4">
                                    <Label>Usuário:</Label>
                                    <Input type="text" value={config.account_user} onChange={(e)=>onChange(e.target.value, "account_user")} placeholder="123456"/>
                                </Col>
                                <Col md="4">
                                    <Label>ID da conta:</Label>
                                    <Input type="text" value={config.account_id} onChange={(e)=>onChange(e.target.value, "account_id")} placeholder="123456"/>
                                </Col>
                                <Col md="4">
                                    <Label>Senha:</Label>
                                    <Input type="text" value={config.account_pass} onChange={(e)=>onChange(e.target.value, "account_pass")} placeholder="123456"/>
                                </Col>
                            </Row>
                            <h3>Login do facebook</h3>
                            <Row className="my-4">
                                <Col md="4">
                                    {
                                        config.facebook_valid ?
                                            <span style={{padding: 8, borderRadius: "10px", backgroundColor: "green", color: "white"}}>Login Válido</span>
                                        : 
                                            <span style={{padding: 8, borderRadius: "10px", backgroundColor: "red", color: "white"}}>Login inválido</span>
                                    }
                                </Col>
                            </Row>
                            <Row className="mb-3">   
                                <Col md="4">
                                    <Label>Email/Celular:</Label>
                                    <Input type="text" value={config.facebook_user} onChange={(e)=>onChange(e.target.value, "facebook_user")} placeholder="email ou celular"/>
                                </Col>
                                <Col md="4">
                                    <Label>Senha:</Label>
                                    <Input type="text" value={config.facebook_pass} onChange={(e)=>onChange(e.target.value, "facebook_pass")} placeholder="123456"/>
                                </Col>
                                {
                                    facebookTrycode && 
                                    <Col md="4">
                                        <Label>Código:</Label>
                                        <Input type="text" value={config.facebook_code} onChange={(e)=>onChange(e.target.value, "facebook_code")} placeholder="123456"/>
                                    </Col>
                                }
                            </Row>
                            <Row className="mb-5">
                                <Col className="position-relative">
                                    <Button className="m-0" style={{
                                        marginTop: 18
                                    }} color="primary" onClick={()=>{
                                        setModal({
                                            status: true,
                                            text: "Deseja fazer login no facebook?",
                                            buttonOk: "Fazer Login",
                                            onOk: getFbLogin
                                        })
                                    }}>Logar</Button>
                                </Col>
                                <Col className="position-relative">
                                    <Button className="m-0" style={{
                                        marginTop: 18
                                    }} color="info" onClick={()=>{
                                        setModal({
                                            status: true,
                                            text: "Deseja sair do facebook?",
                                            buttonOk: "Sair",
                                            onOk: getFbLogout
                                        })
                                    }}>Sair</Button>
                                </Col>
                                {
                                    facebookTrycode &&
                                    <Col className="position-relative">
                                        <Button className="m-0" style={{
                                            marginTop: 18
                                        }} color="warning" onClick={()=>{
                                            setModal({
                                                status: true,
                                                text: "Deseja enviar este código?",
                                                buttonOk: "Enviar",
                                                onOk: getFbCode
                                            })
                                        }}>Enviar Código</Button>
                                    </Col>
                                }

                                  
                                
                            </Row>
                            <FormGroup check className="my-4">
                                <Label check className="mx-1 mt-3">
                                    Pausar o envio de mensagens automáticas
                                    <Input
                                        type="checkbox"
                                        name="pauseMsgAutoSend"
                                        checked={config.pauseMsgAutoSend}
                                        onClick={() => {
                                            setConfig({...config, pauseMsgAutoSend: !config.pauseMsgAutoSend});
                                        }}
                                        />
                                        <span className="form-check-sign">
                                        </span>
                                </Label>
                            </FormGroup>

                            <h4 className="mt-5">Link na mensagem</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de link na mensagem
                                </Label>
                                <Input type="select" value={config.ortoNextTwoDayId} onChange={(e)=>onChange(e.target.value, "ortoNextTwoDayId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoNextTwoDayWeeks} _key={"ortoNextTwoDayWeeks"}/>

                            <h4 className="mt-5">Mensagens de Fidelidade 1 Estrela</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem 1 estrela:
                                </Label>
                                <Input type="select" value={config.fidelityMsg1Id} onChange={(e)=>onChange(e.target.value, "fidelityMsg1Id")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.fidelityMsg1Weeks} _key={"fidelityMsg1Weeks"}/>

                            <h4 className="mt-5">Mensagens de Fidelidade 2 Estrelas</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem 2 estrela:
                                </Label>
                                <Input type="select" value={config.fidelityMsg2Id} onChange={(e)=>onChange(e.target.value, "fidelityMsg2Id")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.fidelityMsg2Weeks} _key={"fidelityMsg2Weeks"}/>

                            <h4 className="mt-5">Mensagens de Fidelidade 3 Estrelas</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem 3 estrela:
                                </Label>
                                <Input type="select" value={config.fidelityMsg3Id} onChange={(e)=>onChange(e.target.value, "fidelityMsg3Id")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.fidelityMsg3Weeks} _key={"fidelityMsg3Weeks"}/>

                            <h4 className="mt-5">Mensagens de Fidelidade 4 Estrelas</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem 4 estrela:
                                </Label>
                                <Input type="select" value={config.fidelityMsg4Id} onChange={(e)=>onChange(e.target.value, "fidelityMsg4Id")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.fidelityMsg4Weeks} _key={"fidelityMsg4Weeks"}/>



                            <h4 className="mt-5">Mensagens de Adesão</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem de adesão:
                                </Label>
                                <Input type="select" value={config.ortoAdesaoOnDemandId} onChange={(e)=>onChange(e.target.value, "ortoAdesaoOnDemandId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoAdesaoOnDemandWeeks} _key={"ortoAdesaoOnDemandWeeks"}/>
                            <h4 className="mt-5">Mensagens de Clinico 10 minutos</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem de clinico:
                                </Label>
                                <Input type="select" value={config.ortoClinicoOnDemandId} onChange={(e)=>onChange(e.target.value, "ortoClinicoOnDemandId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoClinicoOnDemandWeeks} _key={"ortoClinicoOnDemandWeeks"}/>

                            <h4 className="mt-5">Mensagens de geolocalização</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem de geolocalização:
                                </Label>
                                <Input type="select" value={config.autoGeoId} onChange={(e)=>onChange(e.target.value, "autoGeoId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.autoGeoWeeks} _key={"autoGeoWeeks"}/>

                            <h4 className="mt-5">Orto não pagantes (Dia)</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem Orto não pagantes (Dia):
                                </Label>
                                <Input type="select" value={config.ortoToDayId} onChange={(e)=>onChange(e.target.value, "ortoToDayId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoToDayWeeks} _key={"ortoToDayWeeks"}/>
                            

                            <h4 className="mt-5">Orto não pagantes (Amanhã)</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem Orto não pagantes (Amanhã):
                                </Label>
                                <Input type="select" value={config.ortoNextDayId} onChange={(e)=>onChange(e.target.value, "ortoNextDayId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoNextDayWeeks} _key={"ortoNextDayWeeks"}/>
                            
                            <h4 className="mt-5">Orto não pagantes (Últimos 7 Dias)</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem Orto não pagantes (Últimos 7 dias):
                                </Label>
                                <Input type="select" value={config.ortoLastSevenDaysId} onChange={(e)=>onChange(e.target.value, "ortoLastSevenDaysId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoLastSevenDaysWeeks} _key={"ortoLastSevenDaysWeeks"}/>


                            <h4 className="mt-5">Orto não pagantes (Últimos 2 meses)</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem Orto não pagantes (Últimos 2 meses):
                                </Label>
                                <Input type="select" value={config.ortoLastTwoMonthsId} onChange={(e)=>onChange(e.target.value, "ortoLastTwoMonthsId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>

                            
                            <h4 className="mt-5">Aniversariantes do dia</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem Aniversariantes do dia:
                                </Label>
                                <Input type="select" value={config.birthDayDailyId} onChange={(e)=>onChange(e.target.value, "birthDayDailyId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.birthDayDailyWeeks} _key={"birthDayDailyWeeks"}/>
                            











                            
                            <h4 className="mt-5">Manutenção automática</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem de agendamento:
                                </Label>
                                <Input type="select" value={config.ortoManutencaoAutoId} onChange={(e)=>onChange(e.target.value, "ortoManutencaoAutoId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoManutencaoAutoWeeks} _key={"ortoManutencaoAutoWeeks"}/>


                            <h4 className="mt-5">Mensagem de limpeza</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem de limpeza:
                                </Label>
                                <Input type="select" value={config.ortoLimpezaId} onChange={(e)=>onChange(e.target.value, "ortoLimpezaId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoLimpezaWeeks} _key={"ortoLimpezaWeeks"}/>
                            
                            <h4 className="mt-5">Mensagem de manutenção de protocolo</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem de manutenção de protocolo:
                                </Label>
                                <Input type="select" value={config.ortoProtocolId} onChange={(e)=>onChange(e.target.value, "ortoProtocolId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoProtocolWeeks} _key={"ortoProtocolWeeks"}/>
                            
                            <h4 className="mt-5">Mensagem de limpeza de coroa do implante</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem de limpeza de coroa do implante:
                                </Label>
                                <Input type="select" value={config.ortoLimpezaCoroaId} onChange={(e)=>onChange(e.target.value, "ortoLimpezaCoroaId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoLimpezaCoroaWeeks} _key={"ortoLimpezaCoroaWeeks"}/>
                            
                            <h4 className="mt-5">Mensagem de revisão de gengivoplastia</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem de revisão de gengivoplastia:
                                </Label>
                                <Input type="select" value={config.ortoGengivoplastiaId} onChange={(e)=>onChange(e.target.value, "ortoGengivoplastiaId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoGengivoplastiaWeeks} _key={"ortoGengivoplastiaWeeks"}/>

                            <h4 className="mt-5">Mensagem de manutenção das lentes</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem de manutenção das lentes:
                                </Label>
                                <Input type="select" value={config.ortoLentesId} onChange={(e)=>onChange(e.target.value, "ortoLentesId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <WeeksCheck setWeek={setWeek} weeks={config.ortoLentesWeeks} _key={"ortoLentesWeeks"}/>
                            



                            <h4 className="mt-5">Paciente Feliz</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem Paciente Feliz:
                                </Label>
                                <Input type="select" value={config.ortoPacienteFelizId} onChange={(e)=>onChange(e.target.value, "ortoPacienteFelizId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                         
                            <h4 className="mt-5">Convencional para autoligado</h4>
                               
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem Convencional para autoligado:
                                </Label>
                                <Input type="select" value={config.ortoConvencionalAutoligadoId} onChange={(e)=>onChange(e.target.value, "ortoConvencionalAutoligadoId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleSelect">
                                   Dia do envio:
                                </Label>
                                <Input value={config.ortoConvencionalAutoligadoDay} type="number" className="w-25" onChange={(e)=>onChange(e.target.value, "ortoConvencionalAutoligadoDay")}/>
                            </FormGroup>
                            
                            
                            <MonthsCheck _key={'ortoConvencionalAutoligadoMonths'} setMonth={setMonth} months={config.ortoConvencionalAutoligadoMonths}/>

                            <h4 className="mt-5">Clinico geral</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem Clinico geral:
                                </Label>
                                <Input type="select" value={config.ortoClinicoGeralId} onChange={(e)=>onChange(e.target.value, "ortoClinicoGeralId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>

                            <h4 className="mt-5">Fgts</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem fgts:
                                </Label>
                                <Input type="select" value={config.ortoFgtsId} onChange={(e)=>onChange(e.target.value, "ortoFgtsId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>

                   




                            <h4 className="mt-5">Aniversariantes do Mês</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem Aniversariantes do dia:
                                </Label>
                                <Input type="select" value={config.birthDayMonthlyId} onChange={(e)=>onChange(e.target.value, "birthDayMonthlyId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>

                            <h4 className="mt-5">Contas a Receber</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem Contas a Receber:
                                </Label>
                                <Input type="select" value={config.billsToReceiveId} onChange={(e)=>onChange(e.target.value, "billsToReceiveId")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            
                            <h4 className="mt-5">Orto (Dia 28)</h4>
                            <FormGroup>
                                <Label for="exampleSelect">
                                    Modelo de mensagem:
                                </Label>
                                <Input type="select" value={config.lastBillsToReceive28Id} onChange={(e)=>onChange(e.target.value, "lastBillsToReceive28Id")}>
                                    <option value="0">Escolha um modelo de mensagem</option>
                                    {
                                        config && config.msgmodels.map(({name, _id}) => (
                                            <option key={_id} value={_id}>{name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>

                            
                        </CardBody>
                        <CardFooter>
                            <span>
                                <Button color="primary" onClick={()=>saveConfig()}>Salvar</Button>
                            </span>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}