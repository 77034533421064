import styled from "styled-components"

import wallpaper from "../../assets/img/default-wallpaper.jpg"
import ReactMarkdown from 'react-markdown'
import { AiFillCloseCircle, AiFillEdit, AiFillFileText, AiOutlineCloseCircle } from "react-icons/ai";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, } from "reactstrap";
import { useState } from "react";
import vars from "../../services/constants.json"
import { evaluate } from "mathjs";
const api_url = vars.WSSERVER;


const formatMath = (text) => {
    let arr = text.split("mat=(");
    let res = [];
    for (let i = 0; i < arr.length; i++) {
        if(arr[i].includes(")")){
            res.push({
                query: `mat=(${arr[i].split(")")[0]})`,
                content: `${arr[i].split(")")[0]}`,
                res: "",
            })
        }
    }
    return res
}


const formatText = (text, fields) => {
    if(!fields.hasContacts) return text
    const contact = fields.rows[0];
    fields.columns.map(i=>{
        if(i.use){
            text = text.replaceAll(`{{${i.var}}}`, contact[i.index]);
        }
    })
    let maths = formatMath(text);
    for (let i = 0; i < maths.length; i++) {
        if(!maths[i].content) continue
        try {
            maths[i].res =  evaluate(maths[i].content)
            text = text.replace(maths[i].query, maths[i].res);
        } catch (error) {
            console.log("error math:", error);
        }
    }
    return text
}

const ShowDocument = ({media}) => {
    if(media.type === "image") {
        return (
            <ImageBox>
                <img src={media.origin == "local" ? URL.createObjectURL(media.file) : api_url + media.filename }/>
            </ImageBox>
        )
    }

    return (
        <DocumentBox>
            <AiFillFileText/>{media.origin == "local" ? media.file.name : media.filename }
        </DocumentBox>
    )
}

const Message = ({data, isCurrent, fields, index, setModal, deleteMsg, editMsg}) => {
    return (
        <Msg>
            <You>
                <svg viewBox="0 0 8 13" width="8" height="13" class=""><path opacity=".13" fill="#0000000" d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path><path fill="currentColor" d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path></svg>
            </You>
            <MsgText>
                {
                    data.media.has &&
                    <ShowDocument media={data.media}/>
                }
                <div className="markdown">
                    <ReactMarkdown>
                        {formatText(data.texto, fields)}
                    </ReactMarkdown>
                </div>
                {
                    !isCurrent && !fields.ready ?
                    <>
                        <MsgDel>
                            <AiFillCloseCircle onClick={()=>{
                                setModal({
                                    status: true,
                                    text: "Deseja Deletar esta Mensagem?",
                                    buttonOk: "Deletar",
                                    index: index,
                                    onOk: deleteMsg
                                })
                            }}/>
                        </MsgDel>
                        <MsgEdit>
                            <AiFillEdit onClick={()=>{
                                setModal({
                                    status: true,
                                    text: "Deseja Editar esta Mensagem?",
                                    buttonOk: "Editar",
                                    index: data,
                                    onOk: editMsg
                                })
                            }}/>
                        </MsgEdit>
                    </>
                    : null
                }
                
            </MsgText>
        </Msg>
    )
}

export default function MsgTimeLine({currentMsg, fields, vh, setCurrentMsg, setFields}){
    const [modal, setModal] = useState({
        status: false,
        text: "",
        buttonOk: "",
        onOk: false
    });
    
    const deleteMsg = (index) => {
        let msgs = [];
        if(fields.mensagens.length > 1) {
            const msg = fields.mensagens;
            for (let i = 0; i < msg.length; i++) {
                if(index != i) {
                    msgs.push(msg[i])
                }
            }
        }
        setFields({...fields, mensagens: msgs})
    } 
    
    const editMsg = (msg) => {
        console.log('currentMsg:' , currentMsg);
        console.log('editMsg:' , msg);
        console.log('fields:' , fields);
        setCurrentMsg({...msg, method: "edit"})
    } 

    const toggle = () => setModal({...modal, status: !modal.status});

    return (
        <Msgs vh={vh}>
            <Modal isOpen={modal.status} toggle={toggle}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    {modal.text}
                </ModalBody>
                <ModalFooter style={{padding: "24px"}}>
                    <Button color="primary" onClick={ () => {
                        modal.onOk(modal.index);
                        setModal({
                            status: false,
                            text: "",
                            buttonOk: "",
                            onOk: false
                        })
                    }}>{modal.buttonOk}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            <Content>
                <div style={{marginBottom: 75}}>
                    {
                        currentMsg && (currentMsg.media.has || currentMsg.texto != "")  ?
                            <>
                                <Message isCurrent={true} fields={fields} data={currentMsg} />
                            </>
                        : null
                    }
                </div>
                {
                    fields.mensagens.map( (i, k) => (
                        <Message key={"message-"+k} isCurrent={false} fields={fields} data={i} index={k} editMsg={editMsg} setModal={setModal} deleteMsg={deleteMsg}/>
                    ))
                }
            </Content>
        </Msgs>
    )
}

const MsgEdit = styled.div`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -80px;
    cursor: pointer;
    svg {
        color: green!important;
    }
`;

const MsgDel = styled.div`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -40px;
    cursor: pointer;
    svg {
        color: red!important;
    }
`;

const Content = styled.div`
    strong {
        font-weight: 900;
    }
`;

const ImageBox = styled.div`
    display: inline-block;
    img {
        width: 150px;
    }
`;

const DocumentBox = styled.div`
    display: flex;
    align-items: center;
    padding: 12px;
    background-color: #f1f1f1;
    svg {
        margin-right: 4px;
        margin-bottom: 1px;
        font-size: 20px;
    }
`;

const MsgText = styled.div`
    display: inline-block;
    background-color: #fff;
    font-size: 14.2px;
    padding: 8px;
    border-radius: 3px;
    position: relative;
    .markdown * {
        color: #000!important;
    }
`;

const Msg = styled.section`
    position: relative;
    width: 70%;
    flex: none;
    margin: 8px 0;
`;
const You = styled.span`
    color: #fff;
    position: absolute;
    z-index: 100;
    display: block;
    width: 8px;
    height: 13px;
    left: -7px;
    font-size: 100%;
    outline: none;
    padding: 0;
    margin: 0;
    border: 0;
    svg {
        display: block;
        pointer-events: none;
    }
`;

const Msgs = styled.div`
    height: ${p => p.vh}vh;
    padding: 24px;
    padding-right: 24px;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: url("${wallpaper}");
    background-position: center;
    background-size: cover;
`;