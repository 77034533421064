import RelatoryItem from "components/RelatoryItem";
import { useEffect, useState } from "react";
import Api from "services/api";
import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #fff!important;
  }
`;


let async_timeout = 0;

let BRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

const traduction = {
    day: "Dia",
    month: "Mês",
    last_7_days: "7 Dias",
    last_15_days: "15 Dias",
    last_30_days: "30 Dias",
}

global.search_types = {
    type_1: 0,
    type_2: 0,
    ranking: false
}


const RelatorioLista = () => {
    const [relatories, setRelatories] = useState({
        queried: false,
        updated: false,
        timeout: 0,
        type_1_total: 0,
        type_2_total: 0,
        data: []
    });

    const [ranking, setRanking] = useState(false);

    const [search, setSearch] = useState({
        type_1: "day",
        type_2: "month"
    })

    const getRelatories = async () => {
        try {
            const res = await Api("post", "admin/get-odontus-values", {}, 
            {
                headers: {
                    "x-access-token": localStorage.getItem("terces")
                }
            });
            if(res.data) {
                
                let t1t = 0;
                let t2t = 0;
                let data;
                if(global.search_types.ranking) {
                    data = res.data.sort((a, b) => a[global.search_types.type_1] - b[global.search_types.type_1]).reverse().map( i => ({
                        ...i,
                        type_1: i[global.search_types.type_1],
                        type_2: i[global.search_types.type_2],
                    }));
                } else {
                    data = res.data.sort((a, b) => a.order - b.order).map( i => ({
                        ...i,
                        type_1: i[global.search_types.type_1],
                        type_2: i[global.search_types.type_2],
                    }));
                }
            

                for (let i = 0; i < data.length; i++) {
                    const d = data[i];
                    t1t += d.type_1;
                    t2t += d.type_2;
                }
          
                setRelatories({
                    ...relatories,
                    queried: true,
                    timeout: (new Date).getTime(),
                    type_1_total: t1t,
                    type_2_total: t2t,
                    data: data
                });
            }

        } catch (error) {
            console.log("error:",error);
        }

    }

    const changeType = () => {
        let t1t = 0;
        let t2t = 0;
        global.search_types.type_1 = search.type_1;
        global.search_types.type_2 = search.type_2;
        let data;
        if(global.search_types.ranking) {
            data = relatories.data.sort((a, b) => a[global.search_types.type_1] - b[global.search_types.type_1]).reverse().map( i => ({
                ...i,
                type_1: i[global.search_types.type_1],
                type_2: i[global.search_types.type_2],
            }));
        } else {
            data = relatories.data.sort((a, b) => a.order - b.order).map( i => ({
                ...i,
                type_1: i[global.search_types.type_1],
                type_2: i[global.search_types.type_2],
            }));
        }

        for (let i = 0; i < data.length; i++) {
            const d = data[i];
            t1t += d.type_1;
            t2t += d.type_2;
        }
  
        setRelatories({
            ...relatories,
            timeout: (new Date).getTime(),
            type_1_total: t1t,
            type_2_total: t2t,
            data: data
        });
    }

    const changeRanking = () => {
        let t1t = 0;
        let t2t = 0;
        global.search_types.ranking = ranking;

        let data;

        if(global.search_types.ranking) {
            data = relatories.data.sort((a, b) => a[global.search_types.type_1] - b[global.search_types.type_1]).reverse().map( i => ({
                ...i,
                type_1: i[global.search_types.type_1],
                type_2: i[global.search_types.type_2],
            }));
        } else {
            data = relatories.data.sort((a, b) => a.order - b.order).map( i => ({
                ...i,
                type_1: i[global.search_types.type_1],
                type_2: i[global.search_types.type_2],
            }));
        }

        for (let i = 0; i < data.length; i++) {
            const d = data[i];
            t1t += d.type_1;
            t2t += d.type_2;
        }
  
        setRelatories({
            ...relatories,
            timeout: (new Date).getTime(),
            type_1_total: t1t,
            type_2_total: t2t,
            data: data
        });
    }

    useEffect(() => {
        if(!relatories.queried) {
            getRelatories();
        } else {
            setTimeout(() => {
                getRelatories();
            },5000)
        }
    },[relatories]);

    useEffect(() => {
        changeType()
    },[search]);

    useEffect(() => {
        changeRanking()
    },[ranking]);

    return (
        <div style={{
            margin: 0,
            padding: 12,
            width: "100%",
            height: "100vh"
        }}>
            <GlobalStyle/>
            <div  style={{
                height: "100%"
            }}>
                <div className="row">
                    <div className="col-6">
                        <h2 style={{color: "#333", fontWeight: 800}}>Grupo WR</h2>
                    </div>
                    <div className="col-6">
                        <button onClick={() => setRanking(!ranking)} className="float-right px-4 py-1" style={{borderRadius: 24, backgroundColor: ranking ? "#4ba45f" : "#444", color: "#fff", border: "none", fontWeight: 700}}>Ranking</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6  px-2">
                        <select
                            onChange={(e) => setSearch({...search, type_1: e.target.value})}
                            style={{
                                color: "#f1f1f1",
                                fontSize: 18,
                                fontWeight: 600,
                                padding: 4,
                                borderRadius: 12,
                                color: "#ffff",
                                textAlign: "center",
                                backgroundColor: "#333", 
                                border: "none",
                                width: "100%"
                            }}>
                            <option value={"day"} selected>Dia</option>
                            <option value={"month"}>Mês</option>
                            <option value={"last_7_days"}>7 Dias</option>
                            <option value={"last_15_days"}>15 Dias</option>
                            <option value={"last_30_days"}>30 Dias</option>
                        </select>
                    </div>
                    <div className="col-6 px-2">
                        <select 
                            onChange={(e) => setSearch({...search, type_2: e.target.value})}
                            style={{
                                color: "#f1f1f1",
                                fontSize: 18,
                                borderRadius: 12,
                                padding: 4,
                                fontWeight: 600,
                                color: "#ffff",
                                textAlign: "center",
                                backgroundColor: "#333", 
                                border: "none",
                                width: "100%"
                            }}>
                            <option value={"day"}>Dia</option>
                            <option value={"month"} selected>Mês</option>
                            <option value={"last_7_days"}>7 Dias</option>
                            <option value={"last_15_days"}>15 Dias</option>
                            <option value={"last_30_days"}>30 Dias</option>
                        </select>
                    </div>
                </div>
                <div className="mt-3"
                    style={{
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                    >
                    {
                        relatories.data.map( (i, k) => (
                            <RelatoryItem key_index={k}  ranking={ranking} name_1={traduction[search.type_1]} name_2={traduction[search.type_2]}  key={i._id} name={i.name} day_value={BRL.format(i.type_1)} month_value={BRL.format(i.type_2)} relatory_loaded={i.done}/>
                        ))
                    }
                    <div style={{marginTop: 72}}/>
                </div>
                <div
                    className="d-flex align-items-center"
                    style={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        zIndex: 99,
                        backgroundColor: "#333",
                        width: "100%",
                        height: 50
                    }}>
                    <div className="row w-100 px-2">
                        <div className="col-6 px-1">
                            <p className="text-center"  style={{padding: 0, margin: 0, fontWeight: 500}}><span style={{fontWeight: 600}}>{traduction[search.type_1]}: </span>{BRL.format(relatories.type_1_total)}</p>
                        </div>
                        <div className="col-6 px-1">
                            <p className="text-center" style={{padding: 0, margin: 0, fontWeight: 500}}><span style={{fontWeight: 600}}>{traduction[search.type_2]}: </span>{BRL.format(relatories.type_2_total)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default RelatorioLista;