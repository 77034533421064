import styled from "styled-components";

export const QRBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 15vh;
`;

export const QrDesc = styled.section`
    width: 100%;
    text-align: center;
`;

export const QRCodeImageBox = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const QRCodeImage = styled.img`
    margin-top: 48px;
    width: 300px;
    height: 300px;
`;

export const QrCodeRestart = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    h5 {
        width: 100%;
        text-align: center;
    }
    svg {
        cursor: pointer;
        font-size: 128px;
    }
`;