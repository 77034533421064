import Loading from "components/Loading"
import RelatoryLoad from "components/RelatoryLoad"

const { SRelatoryItem } = require("./style")


const RelatoryItem = ({name, key_index, ranking, name_1, name_2, day_value, month_value, relatory_loaded}) => {
    const getMedal = (i) => {
        if(i===0) return "ouro";
        if(i===1) return "prata";
        if(i===2) return "bronze";
    }

    return (    
        <SRelatoryItem>
            {
                ranking && key_index <=2 ?
                    <img src={`/${getMedal(key_index)}.png`} />
                : null
            }
            <div className="relatory-item-content">
                <div>
                    <h3>{name}</h3>
                </div>
                <div className="row">
                    {
                        <>
                            <div className="col-6">
                                <p><span>{name_1}:</span></p>
                                <p>{day_value}</p>
                            </div>
                            <div className="col-6">
                                <p><span>{name_2}:</span></p>
                                <p>{month_value}</p>
                            </div>
                        </>
                    }
                    
                </div>
            </div>
        </SRelatoryItem>
    )
}

export default RelatoryItem