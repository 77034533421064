import RelatorioLista from "views/RelatorioLista"
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import _routes from "routes.js";
import { AuthContext } from "contexts/AuthContext";
import React, { useEffect } from "react";
import Api from "services/api";

const RelatoriosLayout = () => {
    const [auth, setAuth] = React.useContext(AuthContext);

    
    const validJwt = async () => {
        const jwt = localStorage.getItem("terces")
        try {
            const res = await Api("get", "auth/valid", {
                headers: {
                    "x-access-token": jwt
                }
            });

            if(res.data.id){
                let _admin = false;
                res.data.roles.map(i=>{
                    if(i=="ROLE_ADMIN"){
                        _admin = true;
                    }
                })

                if(_admin) {
                    setAuth({
                        ...auth,
                        admin: _admin,
                        auth: res.data
                    })
                } else {
                    localStorage.removeItem("terces");
                    window.location = "/login"
                }
         
            } else {
                localStorage.removeItem("terces");
                window.location = "/login"
            }
        } catch (error) {
            localStorage.removeItem("terces");
            window.location = "/login"
        }
    }
    useEffect(()=>{
        if(auth.auth && !Object.keys(auth.auth).length){
            validJwt();
        }
    },[auth]);
    
    return (
        <RelatorioLista/>
    )
}

export default RelatoriosLayout